import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import showErrMsg from "../Notifications/showErrMsg";
import { Drawer, Form, Button, message } from "antd";
import { closeModal, update } from "../../store/reducers/commonSlice";
import showConfirmModal from "../Notifications/showConfirmModal";
import "./FormDrawer.css";

function FormDrawer({
	form,
	children,
	isAdd = true,
	title,
	disabledSubmit = false,
	handleSubmit,
	handleClose,
	handleFinishFailed = () => {},
	size = "large",
	contentWrapperStyle,
	drawerStyle,
	...props
}) {
	const { visible } = useSelector((state) => state?.common?.drawer);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const close = () => {
		if (typeof handleClose === "function") handleClose();
		dispatch(closeModal());
		form.resetFields();
	};
	const closeForm = () => {
		if (form.isFieldsTouched()) {
			showConfirmModal({
				title: t("message.confirm"),
				onOk: close,
			});
		} else {
			close();
		}
	};
	const submitForm = async (values) => {
		if (typeof handleSubmit === "function") {
			setLoading(true);
			try {
				await handleSubmit(values);
				close();
				dispatch(update());
				message.success(
					isAdd
						? t("function.add", { val: "state.success" })
						: t("alertMessage.success", { action: t("function.edit") })
				);
			} catch (error) {
				showErrMsg(error);
			}
			setLoading(false);
		}
	};

	return (
		<Form
			data-testid="form-drawer_form"
			validateMessages={{
				required: t("validateMsg.required"),
				string: {
					// eslint-disable-next-line
					max: t("validateMsg.maxLength", { val: "${max}" }),
					// eslint-disable-next-line
					range: t("validateMsg.inRange", { min: "${min}", max: "${max}" }),
				},
			}}
			validateTrigger={["onChange", "onBlur"]}
			requiredMark
			labelAlign="left"
			onFinish={submitForm}
			onFinishFailed={handleFinishFailed}
			form={form}
			labelCol={{
				span: 5,
			}}
			wrapperCol={{
				span: 19,
			}}
			{...props}>
			<Drawer
				data-testid="form-drawer_drawer"
				className="form-drawer"
				title={
					isAdd
						? t("function.add", { val: title })
						: t("function.information", { val: title })
				}
				placement="right"
				size={size || "large"}
				drawerStyle={drawerStyle}
				contentWrapperStyle={contentWrapperStyle}
				visible={visible}
				onClose={closeForm}
				footer={
					<Form.Item noStyle shouldUpdate>
						{() => {
							const condition =
								disabledSubmit ||
								!form.isFieldsTouched() ||
								form.getFieldsError().some(({ errors }) => errors.length);
							return (
								<Button
									data-testid="form-drawer_submit"
									htmlType="submit"
									type="primary"
									onClick={() => {
										form.submit();
									}}
									disabled={condition}
									loading={loading}>
									{t(`${isAdd ? "function.confirm" : "function.save"}`)}
								</Button>
							);
						}}
					</Form.Item>
				}
				footerStyle={{
					justifyContent: "flex-end",
					display: "flex",
				}}
				destroyOnClose>
				{children}
			</Drawer>
		</Form>
	);
}

export default memo(FormDrawer);
export { default as InputField } from "./InputField";
export { default as SelectField } from "./SelectField";
export { default as InputNumberField } from "./InputNumberField";
