import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../api";
import { showErrMsg } from "../../../components";
import { SORT_DIRECTIONS } from "../../../constants";
import {
	openModal,
	setLoading,
	update,
} from "../../../store/reducers/commonSlice";
import ShowListDish from "../ShowListDish/ShowListDish";
const InfoDish = ({ title, btn, setTitle, setBtn }) => {
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const { isUpdate, drawer, loading } = useSelector((state) => state.common);
	const [dataSource, setDataSource] = useState([]);
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 10,
		total: "",
		orderby: "id-DESC",
	});
	const { confirm } = Modal;
	const handleDelete = (key) => {
		confirm({
			title: t("message.delete", { val: t("dish") }),
			icon: <ExclamationCircleOutlined />,
			okText: t("function.ok"),
			async onOk() {
				try {
					await Api.menus.deleteById(key);

					message.success(
						t("alertMessage.success", {
							action: t("function.del"),
						})
					);
					dispatch(update());
				} catch (error) {
					dispatch(update());
				}
			},
			cancelText: t("function.cancel"),
			onCancel() {},
		});
	};
	const clickDetail = (record) => {
		dispatch(openModal(record));
		setTitle(i18n.t("function.information", { val: t("dish") }));
		setBtn(i18n.t("function.save"));
	};
	const textLong = (text) => {
		if (text && text.length > 50) {
			return (
				<Tooltip placement="topRight" title={text}>
					{text.slice(0, 50) + "..."}
				</Tooltip>
			);
		}
		return text;
	};
	const columns = [
		{
			title: <span data-testid="menus-title_sort">{i18n.t("dish")}</span>,
			dataIndex: "title",
			sorter: true,
		},
		{
			title: i18n.t("note"),
			dataIndex: "description",
			render: textLong,
		},
		{
			title: "",
			render: (record) => {
				return (
					<div className="float-right">
						<Button
							data-testid={`detail${record.id}`}
							onClick={() => clickDetail(record)}
							type="link">
							{i18n.t("function.detail")}
						</Button>
						<Button
							data-testid={`delete${record.id}`}
							type="link"
							danger
							onClick={() => handleDelete(record.id)}>
							{i18n.t("function.del")}
						</Button>
					</div>
				);
			},
		},
	];
	useEffect(() => {
		dispatch(setLoading(true));
		Api.menus
			.get(pagination)
			.then((response) => {
				const { page, total } = response.data;
				setPagination((prev) => ({ ...prev, page: page, total: total }));
				setDataSource(response.data.data);
			})
			.catch((error) => {
				showErrMsg(error);
			})
			.finally(() => dispatch(setLoading(false)));
	}, [isUpdate, drawer.visible, dispatch]);
	const onChange = (current, filter, sorter) => {
		const orderby = sorter.order
			? `${sorter.field}-${
					sorter.order === "ascend" ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC
			  }`
			: "";
		setPagination((prev) => ({
			...prev,
			page: current.current,
			total: current.total,
			orderby: orderby,
			limit: current.pageSize,
		}));
		dispatch(update());
	};
	return (
		<div>
			<Table
				rowKey={"id"}
				columns={columns}
				dataSource={dataSource}
				onChange={onChange}
				pagination={{
					current: pagination.page,
					pageSize: pagination.limit,
					showSizeChanger: true,
					total: pagination.total,
				}}
				loading={loading}
			/>
			<ShowListDish
				setPagination={setPagination}
				title={title}
				btn={btn}
				dataSource={dataSource}
			/>
		</div>
	);
};
export default InfoDish;
