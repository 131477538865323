import React, { memo } from "react";
import { Form, TimePicker } from "antd";
import { useOfficeTranslation } from "../../../utils/customHooks";
import { formatTimeX } from "../../../settings";
import { RULES, OFFICE } from "../../../constants";
import { InputField } from "../../../components";

// const range = (start, end) => {
//   const result = [];
//   for (let i = start; i < end; i++) {
//     result.push(i);
//   }
//   return result;
// };
// const disabledRangeTime = (_, type) => {
//   if (type === "start") {
//     return {
//       disabledHours: () => range(0, 24).splice(9),
//       disabledMinutes: () => range(31, 60),
//     };
//   }

//   return {
//     disabledHours: () => range(0, 24).splice(11),
//     disabledMinutes: () => range(31, 60),
//   };
// };
function InputForm({ isAdd }) {
  const { t } = useOfficeTranslation();
  return (
    <>
      <InputField
        label={t("office")}
        name="title"
        rules={[RULES.REQUIRED_STRING]}
        placeholder={t("titleStore")}
      />

      <InputField
        label={t("code")}
        name="code"
        rules={[
          {
            required: true,
            type: "string",
            min: RULES.MIN_INPUT,
            max: OFFICE.MAX_CODE,
          },
        ]}
        placeholder={t("codeStore")}
      />
      <Form.Item
        label={t("bookingTime")}
        name="time"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TimePicker.RangePicker
          format={formatTimeX}
          // disabledTime={disabledRangeTime}
        />
      </Form.Item>
      {!isAdd && (
        <>
          <InputField label={t("defaultStore")} name="store_title" disabled />
          <InputField label={t("defaultMenu")} name="menu_title" disabled />
        </>
      )}
    </>
  );
}

export default memo(InputForm);
