import React, { memo } from "react";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/reducers/commonSlice";
import { useFetchData } from "../../utils/customHooks";
import FilterWithLabel from "./FilterWithLabel";
const { Option } = Select;

function Filter({
	name,
	label,
	mode,
	apiCall,
	optionKeys = {
		id: undefined,
		value: undefined,
		title: undefined,
	},
	placeholder = "",
	...props
}) {
	const dispatch = useDispatch();
	const { id = "id", value = "id", title = "title" } = optionKeys;
	const { loading, resData } = useFetchData({
		apiCall,
	});

	return (
		<FilterWithLabel label={label} name={name}>
			<Select
				mode={mode}
				showArrow
				allowClear
				loading={loading}
				maxTagCount="responsive"
				placeholder={placeholder}
				data-testid={`select_${name}`}
				onClear={() => dispatch(setPage({ [name]: "" }))}
				{...props}>
				{resData.map((data) => (
					<Option
						data-testid={`select_${name}-option-${id}`}
						key={data[id]}
						value={data[value]}>
						{data[title]}
					</Option>
				))}
			</Select>
		</FilterWithLabel>
	);
}
export default memo(Filter);
