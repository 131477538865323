import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    listParams:{
        store_id:0,
        type: "day",
        from:"",
        to: "",
    }
}
export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers:{
        setListParams: (state,actions)=>{
            state.listParams = actions.payload
        }
    }
})
export const {setListParams}= reportSlice.actions

export default reportSlice.reducer;