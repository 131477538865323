import React from "react";
import StateDot from "./StateDot";
import { Typography } from "antd";
import { useMemberTranslation } from "../../../../utils/customHooks";
import { colors } from "../../../../constants";
const { Title } = Typography;

function Description({ bookingState, className = "" }) {
	const { t } = useMemberTranslation();
	return (
		<div className={`flex items-center ${className}`}>
			<StateDot color={colors[bookingState]} />
			<Title level={5} className="!m-0 !ml-[0.625rem]">
				{t(`bookingState.${bookingState}`)}
			</Title>
		</div>
	);
}
export default Description;
