import React from "react";
import { useFetchToStore } from "../../../utils/customHooks";
import { useTranslation } from "react-i18next";
import Api from "../../../api";
import { Tooltip } from "antd";
import { LongText, TableDetail, TableWithData } from "../../../components";
import { RULES } from "../../../constants";
import { currency } from "../../../settings";
import { formatNumber } from "../../../utils";
import { useSelector } from "react-redux";

const DefaultText = (text, { isdefault }) => (
	<LongText
		max={RULES.MAX_DISPLAY_LEN}
		className={isdefault ? "text-amber-500" : ""}>
		{text}
	</LongText>
);

function OfficeMenusTable() {
	const { t } = useTranslation();
	const { otherParams } = useSelector((state) => state.common);
	useFetchToStore({
		apiCall: Api.offices_menu.get,
		defaultParams: {
			limit: 0,
			orderby: "office_code-ASC",
		},
		handleData: (resData) => {
			const officeIds = new Set();
			return resData.map((row, i) => {
				const { office_id } = row;
				if (officeIds.has(office_id)) {
					return { ...row, rowSpan: 0 };
				} else {
					const idList = [];
					resData.forEach((resDataRow) => {
						if (resDataRow?.office_id === office_id) {
							idList.push(resDataRow.id);
						}
					});
					officeIds.add(office_id);
					return { ...row, rowSpan: idList.length, idList };
				}
			});
		},
		deps: [otherParams?.office_id],
	});
	const columns = [
		{
			title: <span data-testid="store-title_sort">{t("office")}</span>,
			dataIndex: "office_code",
			rowSpan: 1,
			className: "first-letter:uppercase",
			onCell: ({ rowSpan }) => ({ rowSpan }),
			render: (office_code, { office_title }) => (
				<Tooltip title={office_title} placement="bottomLeft">
					{office_code}
				</Tooltip>
			),
		},
		{
			title: t("dish"),
			dataIndex: "menu_title",
			className: "first-letter:uppercase",
			render: DefaultText,
		},
		{
			title: t("store"),
			dataIndex: "store_title",
			className: "first-letter:uppercase",
			render: DefaultText,
		},
		{
			title: `${t("office_menus:price.short")} (${currency})`,
			dataIndex: "price",
			className: "first-letter:uppercase",
			render: (price) => formatNumber(price),
		},
		{
			onCell: ({ rowSpan }) => ({ rowSpan }),
			width: "15%",
			render: (_, row) => {
				const { idList, office_id } = row;
				return (
					idList?.length && (
						<TableDetail
							id={office_id}
							pageName="menu"
							handleDelete={async () => {
								return Api.offices_menu.deleteMany(idList);
							}}
						/>
					)
				);
			},
		},
	];
	return (
		<TableWithData className="mt-6" columns={columns} pagination={false} />
	);
}

export default OfficeMenusTable;
