import React from "react";
import { Typography, Form } from "antd";

const { Text } = Typography;
const { Item } = Form;

export default function FilterWithLabel({
	label,
	name,
	children = null,
	...props
}) {
	return (
		<Item
			className="flex flex-col min-w-[15.5rem] max-w-[16rem] mb-0 only:flex-col"
			label={
				<Text className="first-letter:uppercase inline-block text-black">
					{label}
				</Text>
			}
			name={name}
			labelAlign="left"
			{...props}>
			{children}
		</Item>
	);
}
