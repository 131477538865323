import { Tag, Tooltip } from "antd";
import React from "react";
import { LongText } from "../../../../components";
import { colors, RULES } from "../../../../constants";
import { useMemberTranslation } from "../../../../utils/customHooks";

const stateColor = [
	[
		{ color: colors.info, name: "processing" },
		{ color: colors.danger, name: "error" },
	],
	[
		{ color: colors.warning, name: "warning" },
		{ color: colors.success, name: "success" },
	],
];

function StateDot({ booked, ate, color, size, menu, expand = false }) {
	const { t } = useMemberTranslation();
	let backgroundColor;
	let dish = booked ? menu : t("no.t");
	let tagColor;
	if (color) {
		backgroundColor = color;
	} else {
		if (typeof booked === "number" && typeof ate === "number") {
			backgroundColor = stateColor[booked][ate].color ?? "#BFBFBF";
			tagColor = stateColor[booked][ate].name;
		} else {
			backgroundColor = "#bfbfbf";
			dish = "";
		}
	}

	return expand ? (
		<Tag color={tagColor}>
			<LongText max={RULES.MAX_DISPLAY_LEN}>{dish}</LongText>
		</Tag>
	) : (
		<Tooltip title={dish}>
			<div
				className={
					"inline-block aspect-square rounded-[50%] cursor-pointer " +
					(size === "sm" ? "w-2" : "w-4")
				}
				style={{
					backgroundColor,
				}}></div>
		</Tooltip>
	);
}
export default StateDot;
