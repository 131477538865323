import React from "react";
import { Form, Select } from "antd";
import { useFetchData } from "../../../utils/customHooks";

const { Item } = Form;
const { Option } = Select;

function SelectField({
	name,
	noStyle,
	shouldUpdate = (prev, curr) => prev[name] !== curr[name],
	field = {},
	apiCall,
	rules,
	callData,
	Text,
	label,
	children,
	placeholder,
	contentKeys = { title: undefined, valueKey: undefined },
	...props
}) {
	const { resData, loading } = useFetchData({
		apiCall: apiCall,
		data: callData,
	});
	const { titleKey = "title", valueKey = "id" } = contentKeys;

	return Text ? (
		<Item noStyle={noStyle} label={label} shouldUpdate={shouldUpdate}>
			{({ getFieldValue }) => <Text>{getFieldValue(name)}</Text>}
		</Item>
	) : (
		<Item noStyle={noStyle} name={name} label={label} rules={rules} {...field}>
			<Select
				loading={loading}
				placeholder={placeholder}
				allowClear
				maxTagCount="responsive"
				{...props}
				data-testid={`select_${name}`}>
				{children}
				{resData.map((data) => (
					<Option key={data[valueKey]} value={data[valueKey]}>
						{data[titleKey]}
					</Option>
				))}
			</Select>
		</Item>
	);
}

export default React.memo(SelectField);
