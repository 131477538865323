import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setPage } from "../../../../store/reducers/commonSlice";

import { FilterForm, SearchInput } from "../../../../components";
import { useStoreTranslation } from "../../../../utils/customHooks";

function StoreFilter({ className = "" }) {
	const dispatch = useDispatch();
	const { t } = useStoreTranslation();

	return (
		<FilterForm
			className={className}
			handleFinish={(values) => {
				let office_code = "";
				if (Array.isArray(values?.office_code)) {
					office_code = values.office_code.join("-");
				}
				dispatch(setPage({ office_code, keyword: values.keyword, page: 1 }));
			}}>
			<SearchInput placeholder={`${t("address")}, ${t("mobile")}`} />
		</FilterForm>
	);
}

export default memo(StoreFilter);
