import moment from "moment";

export function getWeekDates(week) {
	let d = moment(moment(week).startOf("week")).add(1, "day");
	const dates = [];
	for (let i = 0; i < 5; i++) {
		dates.push(d.format("DD/MM/YYYY"));
		d.add(1, "day");
	}
	return dates;
}
