export const MAX_DISPLAY_LEN = 20;
export const MAX_NUM_LEN = 10;
export const MAX_INPUT_LEN = 200;
export const MIN_INPUT = 3;
export const REQUIRED_STRING = {
	required: true,
	type: "string",
	min: MIN_INPUT,
	max: MAX_INPUT_LEN,
};
