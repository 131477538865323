import { Form, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Api from "../../../../api";
import { showErrMsg } from "../../../../components";
import { useMenusTranslation } from "../../../../utils/customHooks";
import "./TableMenu.css";
import useCreateCols from "./useCreateCols";

const TableMenu = ({
	chosenDishes = [],
	setDefaultDishSelection,
	office_id,
}) => {
	const form = Form.useFormInstance();
	const { t } = useMenusTranslation();
	const [loading, setLoading] = useState(false);
	const [tableMenus, setTableMenus] = useState([]);
	const [styleError, setStyleError] = useState({});
	const [textErrorMenus, setTextErrorMenus] = useState("");
	const [tableStore, setTableStore] = useState([]);

	const selectedRowKeys = useMemo(() => chosenDishes.map((dish) => dish.id));

	const onSelectChange = useCallback((newSelectedRowKeys, value) => {
		setDefaultDishSelection(value);
		if (newSelectedRowKeys?.length > 0) {
			setStyleError({});
			setTextErrorMenus("");
		} else {
			setStyleError({ border: "1px solid red" });
			setTextErrorMenus(t("textErrorMenus"));
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		Api.menus
			.getAll()
			.then((response) => {
				// trả ra danh sách món ăn
				setTableMenus(response?.data?.data);
			})
			.catch(showErrMsg)
			.finally(() => setLoading(false));
	}, []);
	// trả ra danh sách quán ăn
	useEffect(() => {
		Api.stores
			.filterByMenu()
			.then((response) => {
				setTableStore(response?.data?.data);
			})
			.catch(showErrMsg);
	}, []);

	const columns = useCreateCols({
		office_id,
		selectedRowKeys,
		allStores: tableStore,
	});

	return (
		<>
			<div className="my-3">{t("tableMenu")}</div>
			<div
				style={styleError}
				className="h-[425px] overflow-y-auto mb-0 table_menus  ">
				<Table
					className="table_menus-select"
					loading={loading}
					rowSelection={{
						selectedRowKeys,
						onChange: onSelectChange,
						onSelect: (row, selected) => {
							if (!selected) {
								form.setFields([
									{
										name: ["menu", row?.id, "store_id"],
										errors: [],
									},
									{
										name: ["menu", row?.id, "price"],
										errors: [],
									},
									{
										name: ["menu", row?.id, "priceten"],
										errors: [],
									},
								]);
							}
						},
					}}
					pagination={false}
					columns={columns}
					dataSource={tableMenus}
					rowKey="id"
				/>
			</div>
			<div className="text-red-600 mb-5 mt-1 text-right">{textErrorMenus}</div>
		</>
	);
};

export default React.memo(TableMenu);
