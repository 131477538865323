import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMemberTranslation } from "../../../../utils/customHooks";
import useCols from "./useCols";
import { useFetchToStore } from "../../../../utils/customHooks";
import { setExpand, setWeek } from "../../../../store/reducers/memberSlice";

import { DatePicker, Button } from "antd";
import { TableWithData } from "../../../../components";
import Description from "./Description";

import moment from "moment";
import Api from "../../../../api";
import { colors } from "../../../../constants";
import { parseMembers } from "../../../../utils/parseUtils";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import EyeButton from "../../../../components/EyeButton";

function MemberTable() {
	const { t } = useMemberTranslation();
	const columns = useCols();
	const dispatch = useDispatch();
	const { otherParams } = useSelector((state) => state?.common);
	const { week } = useSelector((state) => state?.member);
	useFetchToStore({
		apiCall: Api.members.get,
		deps: [otherParams?.office_id],
		handleData: parseMembers,
	});

	return (
		<>
			<div className="flex items-center my-4 w-full">
				<div className="flex items-center child:ml-[3.125rem] child-first:ml-0">
					{colors.colorState.map((bookingState) => (
						<Description key={bookingState} bookingState={bookingState} />
					))}
				</div>
				<EyeButton
					className="ml-auto mr-2"
					onClick={(show) => dispatch(setExpand(show))}
				/>
				<DatePicker
					value={moment(week)}
					picker="week"
					placeholder={t("function.selectWeek")}
					onChange={(newWeek) => {
						if (newWeek) dispatch(setWeek(newWeek.format()));
					}}
				/>
				<Button
					type="link"
					data-testid="select-this-week"
					onClick={() => {
						dispatch(setWeek(moment().format()));
					}}>
					{t("function.thisWeek")}
				</Button>
			</div>
			<TableWithData rowKey="username" columns={columns} />
		</>
	);
}

export default MemberTable;
