import React, { useEffect, useState } from "react";
import PageLayout from "../PageLayout";
import ShowTotalOffice from "./components/ShowTotalOffice";
import ChartStatistics from "./components/chartStatistics";
import { useSelector } from "react-redux";
import SelectDate from "./components/chartStatistics/SelectDate";
import reportTodayApi from "../../api/reportTodayApi";
import { showErrMsg } from "../../components";

function Statistic() {
	const { listParams } = useSelector((state) => state.report);
	const [dataChart, setDataChart] = useState([]);
	const [dataDay, setDataDay] = useState([]);
	const [dataWeek, setDataWeek] = useState([]);
	useEffect(() => {
		const callReportApi = async (params) => {
			try {
				const { data } = await reportTodayApi.getData(params);
				setDataChart(data.chart);
				setDataDay(data.thisday);
				setDataWeek(data.thisweek);
			} catch (error) {
				showErrMsg(error);
			}
		};
		callReportApi(listParams);
	}, [listParams]);

	return (
		<PageLayout heading="statistic" addBtn={false}>
			<ShowTotalOffice dataDay={dataDay} dataWeek={dataWeek} />
			<SelectDate />
			<ChartStatistics dataChart={dataChart} />
		</PageLayout>
	);
}
export default Statistic;
