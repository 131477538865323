import React, { useMemo } from "react";
import { useMemberTranslation } from "../../../../utils/customHooks/index";
import moment from "moment";
import { Select, Form, Input } from "antd";
import { LongText, SelectField } from "../../../../components";
import { RULES } from "../../../../constants";
import Api from "../../../../api";
import {
	startMenu,
	endMenu,
	startEat,
	endEat,
	formaDateMonth,
	timeStamp,
	formatYearDate,
} from "../../../../settings";
const { Option } = Select;
const { Item, useFormInstance } = Form;
const today = moment().format(formatYearDate);
const MaxText = (text) => (
	<LongText max={RULES.MAX_DISPLAY_LEN}>{text}</LongText>
);
export default function useColumns() {
	const { t } = useMemberTranslation();
	const form = useFormInstance();
	return useMemo(() => {
		const column = [
			{
				key: "date",
				className: "capitalize",
				width: "5rem",
				title: t("date"),
				dataIndex: "date",
				render: (record) => (
					<span>{moment(record).format(formaDateMonth)}</span>
				),
			},
			{
				key: "menu",
				className: "capitalize child:w-full",
				title: t("book"),
				dataIndex: "menu",
				editable: true,
				render: (record, { date }) => {
					if (today === date && startEat <= timeStamp && endEat >= timeStamp) {
						return (
							<SelectField
								name="menu_id"
								noStyle
								apiCall={async () => Api.menus.get({ access: 1, limit: 0 })}
								onSelect={(value) => {
									if (value === "") {
										form.setFieldsValue({
											ate: 0,
										});
									}
								}}>
								<Option key={t("no.t")} value={""} onClick={() => {}}>
									{t("no.t")}
								</Option>
							</SelectField>
						);
					} else {
						return MaxText(record);
					}
				},
			},
			{
				key: "ate",
				className: "capitalize child:w-full",
				title: t("eat"),
				dataIndex: "ate",
				width: "15%",
				editable: true,
				render: (record, { date }) => {
					if (
						today === date &&
						startMenu <= timeStamp &&
						endMenu >= timeStamp
					) {
						return (
							<Item name="ate" noStyle>
								<Select>
									<Option key={0} value={1}>
										{t("yes.t")}
									</Option>
									<Option key={1} value={0}>
										{t("no.t")}
									</Option>
								</Select>
							</Item>
						);
					} else {
						return <span>{record === 1 ? t("yes.t") : t("no.t")}</span>;
					}
				},
			},
			{
				title: t("note"),
				className: "capitalize",
				dataIndex: "notes",
				editable: true,
				render: (notes, { date }) => {
					if (
						today === date &&
						startMenu <= timeStamp &&
						endMenu >= timeStamp
					) {
						return (
							<Item name="notes" noStyle>
								<Input />
							</Item>
						);
					} else {
						return MaxText(notes);
					}
				},
			},
			{
				key: "office",
				className: "capitalize",
				width: "15%",
				title: t("office"),
				dataIndex: "office_code",
			},
		];
		return column;
	}, [t]);
}
