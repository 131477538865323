import translations from "./translations";
import store from "./store";
import office from "./office";
import member from "./member";
import statistic from "./statistic"
const vi = {
	translations,
	store,
	office,
	member,
	statistic,
};

export default vi;
