export const parseStores = (stores) => {
	if (Array.isArray(stores)) {
		return stores.map(parseStore);
	}
	return [];
};

export const parseStore = (store) => {
	if (typeof store === "object")
		return {
			...store,
			offices: parseToArray(store?.offices),
			menus: parseToArray(store?.menus),
		};
	return {};
};

export const parseMembers = (members) => {
	if (Array.isArray(members)) {
		return members.map(parseMember);
	}
	return [];
};

export const parseMember = (member) => {
	if (typeof member === "object")
		return {
			...member,
			history: parseToArray(member?.history),
		};
	return {};
};

export const parseToArray = (jsonArray) =>
	typeof jsonArray === "string" ? JSON.parse(jsonArray) : [];
