import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { regex } from "../../constants";
import { setPage } from "../../store/reducers/commonSlice";
import FilterWithLabel from "./FilterWithLabel";

function SearchInput({
	onChange = () => {},
	placeholder,
	allowSpecialChars = true,
	...props
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<FilterWithLabel name="keyword" label={t("function.search")}>
			<Input
				placeholder={placeholder}
				allowClear
				onChange={(e) => {
					if (e?.target?.value === "") dispatch(setPage({ keyword: "" }));
				}}
				onKeyDown={(e) => {
					const value = e.key;
					if (!allowSpecialChars && value && regex.specialChars.test(value)) {
						regex.specialChars.test(value);
						e.preventDefault();
					}
				}}
				data-testid="search_input"
				{...props}
			/>
		</FilterWithLabel>
	);
}
export default SearchInput;
