import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import vi from "./locales/vi";

const resources = {
  en,
  vi,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: "translations",
    defaultLng: "vi",
    fallbackLng: "vi",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
