import React, { memo, useEffect, useCallback } from "react";
import { Form } from "antd";
import Api from "../../../api";
import { formatTime, formatTimeX } from "../../../settings";
import { FormDrawer, showErrMsg } from "../../../components";
import InputForm from "./inputForm";
import { useSelector } from "react-redux";
import moment from "moment";

function FormOffice() {
	const { id, visible } = useSelector((state) => state?.common?.drawer);
	const [form] = Form.useForm();
	const isAdd = typeof id !== "number";

	useEffect(() => {
		const initValue = async () => {
			const { data } = await Api.offices.getById(id).catch(showErrMsg);
			if (data?.data) {
				const keys = ["title", "code", "store_title", "menu_title"];
				const fields = [];
				for (const key of keys) {
					fields.push({
						name: key,
						value: data.data[key],
					});
				}
				fields.push({
					name: "time",
					value: [
						moment(data.data.starttime, formatTimeX),
						moment(data.data.endtime, formatTimeX),
					],
				});

				form.setFields(fields);
			}
		};
		if (visible && !isAdd) initValue();
	}, [isAdd, visible, id]);

	const handleFinish = useCallback(
		async (values) => {
			const { time, ...restValues } = values;
			const newValues = Object.assign(
				{
					starttime: moment(time[0], formatTime).format(formatTime),
					endtime: moment(time[1], formatTime).format(formatTime),
				},
				restValues
			);
			if (isAdd) {
				await Api.offices.add(newValues);
			} else {
				await Api.offices.editById(id, newValues);
			}
		},
		[id, isAdd]
	);
	return (
		<>
			<FormDrawer
				title="office"
				handleSubmit={handleFinish}
				form={form}
				isAdd={isAdd}>
				<InputForm isAdd={isAdd} />
			</FormDrawer>
		</>
	);
}

export default memo(FormOffice);
