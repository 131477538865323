import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFetchToStore } from "../../../../utils/customHooks";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import Api from "../../../../api";
import AntNoData from "../../../../components/AntNoData";
import { TODAY_BOOK } from "../../../../constants";
import { setData } from "../../../../store/reducers/commonSlice";
import LongText from "../LongText";
import AntSpin from "./components/AntSpin";
import CombineDataCell from "./components/CombineDataCell";
import "./HandmadeTable.css";

function HandmadeTable() {
	const { t } = useTranslation();
	const { loading, otherParams, data } = useSelector((state) => state?.common);
	const dispatch = useDispatch();
	useFetchToStore({
		apiCall: Api.today_book.get,
		deps: [otherParams?.store_id],
	});

	const rows = useMemo(() => {
		const stores = [];

		for (const [storeIndex, store] of data.entries()) {
			const menu = [];
			const combineData = [];
			if (Array.isArray(store?.menus)) {
				for (const [dishIndex, dish] of store.menus.entries()) {
					menu.push(
						<li className="table-row" key={dish.id}>
							<div className="table-cell dish-col col-gray">
								<LongText>{dish.title}</LongText>
							</div>
							<ul className="rows-in-cell">
								{dish.users.map((user, index) => {
									if (
										!dish?.expand &&
										index >= TODAY_BOOK.DEFAULT_DISPLAY_USER_NUM
									) {
										return null;
									}
									return (
										<li className="table-row" key={user.username}>
											<div className="table-cell member-col user-name-cell">
												{user.fullname}
												{user?.notes && (
													<Tooltip title={user?.notes} placement="bottom">
														<ExclamationCircleOutlined />
													</Tooltip>
												)}
											</div>
											<div className="table-cell office-col col-gray">
												{user.code}
											</div>
										</li>
									);
								})}
								{dish.users.length > TODAY_BOOK.DEFAULT_DISPLAY_USER_NUM && (
									<li className="table-row" key="expand">
										<div className="table-cell member-col user-name-cell">
											<Button
												type="link"
												onClick={() => {
													const newData = [...data];
													const newMenu = [...data[storeIndex].menus];
													newMenu.splice(
														dishIndex,
														1,
														Object.assign({}, newMenu[dishIndex], {
															expand: !newMenu[dishIndex].expand,
														})
													);
													newData.splice(
														storeIndex,
														1,
														Object.assign({}, data[storeIndex], {
															menus: newMenu,
														})
													);
													dispatch(setData(newData));
												}}>
												{t(`function.${dish.expand ? "hide" : "showMore"}`, {
													val:
														dish.users.length -
														TODAY_BOOK.DEFAULT_DISPLAY_USER_NUM,
												})}
											</Button>
										</div>
									</li>
								)}
							</ul>
						</li>
					);
					if (Array.isArray(dish?.offices)) {
						for (const office of dish.offices) {
							const foundIndex = combineData.findIndex(
								(data) => data.id === office.id
							);
							if (foundIndex >= 0) {
								combineData[foundIndex].menu.push({
									id: dish.id,
									count: office.count,
									title: dish.title,
									notes: office.notes,
								});
							} else {
								combineData.push({
									...office,
									menu: [
										{
											id: dish.id,
											count: office.count,
											title: dish.title,
											notes: office.notes,
										},
									],
								});
							}
						}
					}
				}
			}

			stores.push(
				<div className="table-row" key={store.id}>
					<div className="table-cell store-col">
						<LongText>{store.title}</LongText>
					</div>
					<ul className="rows-in-cell">{menu}</ul>
					{<CombineDataCell offices={combineData} id={store.id} />}
				</div>
			);
		}

		return stores;

		// return stores;
	}, [data]);

	return (
		<div className="today-table">
			<div className="table-title">
				<div className="table-head table-cell store-col">{t("store")}</div>
				<div className="flex flex-1">
					<div className="table-head table-cell dish-col">{t("dish")}</div>
					<div className="flex flex-1">
						<div className="table-head table-cell member-col">
							{t("member")}
						</div>
						<div className="table-head table-cell office-col">
							{t("office")}
						</div>
					</div>
				</div>
				<div className="table-head table-cell combine-col">{t("combine")}</div>
			</div>
			{loading ? (
				<AntSpin />
			) : (
				<div className="table-body">
					{rows.length > 0 ? rows : <AntNoData />}
				</div>
			)}
		</div>
	);
}

export default HandmadeTable;
