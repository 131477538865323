export const formatTime = "HH:mm:ss";
export const formatTimeX = "HH:mm";
export const formatDate = "DD/MM/YYYY";
export const formaDateMonth = "DD/MM";
export const currency = "VNĐ";
export const startMenu = new Date().setHours(1, 30, 12).valueOf();
export const endMenu = new Date().setHours(20, 30, 12).valueOf();
export const startEat = new Date().setHours(1, 0, 12).valueOf();
export const endEat = new Date().setHours(17, 15, 12).valueOf();
export const timeStamp = new Date().valueOf();
export const formatYearDate = "yyyy-MM-DD";
