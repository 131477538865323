import axios from "axios";
import qs from "qs";
import { getToken } from "./Store/localStorage";
import i18n from "../translation";

const axiosClient = axios.create({
	// baseURL: process.env.REACT_APP_BASE_URL,
	baseURL: process.env.REACT_APP_URL_BOOKING, // api dev
	headers: {
		"content-type": "application/json",
	},
	timeout: 3000,
	timeoutErrorMessage: i18n.t("message.timeout"),
	paramsSerializer: (params) =>
		qs.stringify(params, {
			skipNull: true,
			skipEmptyString: true,
		}),
});

// Interceptors
axiosClient.interceptors.request.use(function (config) {
	config.headers = {
		"Content-Type": "application/json",
		Authorization: getToken() ? `Bearer ${getToken()}` : undefined,
	};
	return config;
});

axiosClient.interceptors.response.use(
	function (response) {
		if (response.status === 200) {
			return response;
		}
		return Promise.reject(response);
	},
	function (error) {
		console.log("error :", error);
		// if (error.response.status === 401)
		// 	window.location.reload(false);
		return Promise.reject(error);
	}
);

export default axiosClient;
