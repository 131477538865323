import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useCols = () => {
	const { t } = useTranslation();
	return useMemo(
		() => [
			{
				title: t("dish"),
				dataIndex: "title",
			},
			{
				title: t("note"),
				dataIndex: "description",
			},
		],
		[t]
	);
};

export default useCols;
