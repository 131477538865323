import React, { useMemo, memo } from "react";
import { Table, Form } from "antd";
import { useFetchData } from "../../../../utils/customHooks";
import useCols from "./useCols";
import Api from "../../../../api";

const { useFormInstance } = Form;

function MenuTable({ errors }) {
	const cols = useCols();
	const form = useFormInstance();
	const { resData, loading } = useFetchData({
		apiCall: Api.menus.getAll,
	});
	const selectedMenus = form.getFieldValue("menus");
	const selectedRowKeys = useMemo(() => {
		if (Array.isArray(selectedMenus)) {
			return selectedMenus.map((dish) => dish.id);
		}
	}, [selectedMenus]);

	return (
		<>
			<Table
				rowKey="id"
				className={
					errors?.length > 0 ? "border-red-400 border border-solid rounded" : ""
				}
				dataSource={resData}
				rowSelection={{
					type: "checkbox",
					columnWidth: "5.875rem",
					selectedRowKeys,
					getCheckboxProps: ({ id }) => {
						return {
							"data-testid": `store-form_menu-select-${id}`,
						};
					},
					onChange: (_, selectedMenus) => {
						form.setFieldsValue({ menus: selectedMenus });
					},
				}}
				scroll={{
					y: 700,
				}}
				pagination={false}
				columns={cols}
				loading={loading}
			/>
		</>
	);
}

export default memo(MenuTable);
