import { Form, InputNumber } from "antd";
import React from "react";
import KEYS from "../../constants/specialKeys";

function InputNumberField({
	formItemClassName,
	rules,
	name,
	placeholder,
	shouldUpdate,
	noStyle,
	fieldProps = {},
	...props
}) {
	return (
		<Form.Item
			className={formItemClassName}
			noStyle={noStyle}
			name={name}
			rules={rules}
			shouldUpdate={shouldUpdate}
			{...fieldProps}>
			<InputNumber
				className="flex-1"
				min={0}
				placeholder={placeholder}
				onKeyDown={(e) => {
					if (!KEYS.includes(e.key) && /\D/g.test(e.key)) {
						e.preventDefault();
					}
				}}
				formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
				parser={(x) => parseFloat(`${x}`.replace(/\./g, ""))}
				{...props}
			/>
		</Form.Item>
	);
}

export default InputNumberField;
