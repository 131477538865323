import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import i18n from "../../translation";

const { confirm } = Modal;

const showConfirmModal = (props) => {
	confirm({
		maskClosable: true,
		icon: <ExclamationCircleOutlined />,
		okText: i18n.t("function.ok"),
		cancelText: i18n.t("function.cancel"),
		cancelButtonProps: { "data-testid": "confirm-modal_cancel-btn" },
		okButtonProps: { "data-testid": "confirm-modal_ok-btn" },
		...props,
	});
};

export default showConfirmModal;
