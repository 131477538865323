import React, { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useStoreTranslation } from "../../../../utils/customHooks";

import { Form, Radio } from "antd";
import { FormDrawer, InputField, showErrMsg } from "../../../../components";
import MenuTable from "../MenuTable";

import { LineOutlined } from "@ant-design/icons";
import Api from "../../../../api";
import { RULES, STORE } from "../../../../constants";
import { parseStore } from "../../../../utils/parseUtils";
import InputNumberField from "../../../../components/FormDrawer/InputNumberField";

const { Item, List, useForm, ErrorList } = Form;

function StoreForm() {
	const { t, i18n } = useStoreTranslation();
	const [form] = useForm();
	const { visible, id } = useSelector((state) => state?.common?.drawer);

	const isAdd = typeof id !== "number";
	useEffect(() => {
		const getData = async () => {
			const fields = [];

			if (!isAdd) {
				try {
					const { data } = await Api.stores.getById(id);
					const store = parseStore(data.data);
					for (const key in form.getFieldsValue()) {
						if (Object.hasOwnProperty.call(store, key)) {
							fields.push({
								name: key,
								value: store[key],
								touched: false,
							});
						}
					}
				} catch (error) {
					showErrMsg(error);
				}
			} else {
				fields.push(
					{
						name: "status",
						value: 1,
						touched: false,
					},
					{ name: "min", value: 0 },
					{ name: "max", value: 0 }
				);
			}
			form.setFields(fields);
		};
		visible && getData();
		// eslint-disable-next-line
	}, [id, visible]);

	const handleSubmit = useCallback(
		async ({ min, max, mobile, ...values }) => {
			const data = Object.assign({}, values, {
				mobile: mobile.replace(/\s/g, ""),
				min: min ? min : 0,
				max: max ? max : 0,
			});
			if (isAdd) {
				await Api.stores.add(data);
			} else {
				await Api.stores.editById(id, data);
			}
		},
		// eslint-disable-next-line
		[isAdd, id]
	);

	form.getFieldsError();
	return (
		<FormDrawer
			title="store"
			form={form}
			isAdd={isAdd}
			handleSubmit={handleSubmit}>
			<InputField
				label={t("name")}
				name="title"
				rules={[RULES.REQUIRED_STRING]}
				placeholder={t("placeholder.name")}
			/>
			<InputField
				label={t("address")}
				name="address"
				rules={[RULES.REQUIRED_STRING]}
				placeholder={t("placeholder.address")}
			/>
			<InputField
				label={t("contact")}
				name="mobile"
				rules={[
					{
						required: true,
					},
					{
						validator(_, value) {
							if (value) {
								let str = "";
								const arr = value.split("");
								arr.forEach((s) => {
									if (!/\s/.test(s)) {
										str += s;
									}
								});
								if (/^(\+|)(?:(\d{7,15}|[\d ]{7,15}))$/g.test(str)) {
									return Promise.resolve(str);
								}
								return Promise.reject(new Error(t("validateMsg.invalidPhone")));
							}
							return Promise.resolve(value);
						},
					},
				]}
				placeholder={t("placeholder.contact")}
			/>
			<Item label={t("limitRange")}>
				<div className="flex gap-4 items-center">
					<InputNumberField
						noStyle
						placeholder={t("placeholder.min")}
						name="min"
					/>
					<LineOutlined />
					<InputNumberField
						noStyle
						placeholder={t("placeholder.max")}
						name="max"
					/>
				</div>
			</Item>
			<List
				name="menus"
				rules={[
					{
						validator: async (_, menu) => {
							if (!menu?.length) {
								return Promise.reject(new Error(t("validateMsg.requiredMenu")));
							}
						},
					},
				]}>
				{(fields, _, { errors }) => {
					return (
						<>
							<MenuTable form={form} fields={fields} errors={errors} />
							<ErrorList errors={errors} className="text-right" />
						</>
					);
				}}
			</List>
			<Item
				className="mt-6"
				rules={[{ required: true }]}
				name="status"
				label={t("state")}
				labelCol={{
					span: 24,
				}}>
				<Radio.Group>
					<Radio value={STORE.STATE.ACTIVE}>{i18n.t("state.active")}</Radio>
					<Radio value={STORE.STATE.LOCKED}>{i18n.t("state.locked")}</Radio>
				</Radio.Group>
			</Item>
		</FormDrawer>
	);
}

export default memo(StoreForm);
