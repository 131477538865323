import React from "react";
import PageLayout from "../PageLayout";
import MemberFilterForm from "./components/MemberFilterForm";
import MemberTable from "./components/MemberTable";
import MemberForm from "./components/MemberForm";
import { LoadingButton } from "../../components/Button";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";
import Api from "../../api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/reducers/commonSlice";

function Member() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<PageLayout
			heading="member"
			ActionBtn={
				<>
					<LoadingButton
						asyncHandleClick={async () => {
							await Api.syncMember().catch((e) => {});
							dispatch(update());
						}}
						icon={<SyncOutlined />}>
						{t("function.sync", { val: "member" })}
					</LoadingButton>
					<LoadingButton
						asyncHandleClick={async () => {
							await Api.bookAll().catch((e) => {
								console.log("e :", e);
							});
							dispatch(update());
						}}
						icon={<SyncOutlined />}>
						{t("member:bookAll")}
					</LoadingButton>
				</>
			}>
			<MemberFilterForm />
			<MemberTable />
			<MemberForm />
		</PageLayout>
	);
}

export default Member;
