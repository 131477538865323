export const TODAY_BOOK = Object.freeze({
	FORMAT_DATE: "DD/MM",
	DEFAULT_DISPLAY_USER_NUM: 4,
});
export const OFFICE = Object.freeze({
	MAX_CODE: 10,
});
export const STORE = Object.freeze({
	STATE: Object.freeze({
		ACTIVE: 1,
		LOCKED: 0,
	}),
});
export const MAX_PRICE = 35000;
