import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMemberTranslation } from "../../../../utils/customHooks";

import { Tooltip, Typography } from "antd";
import { LongText, TableDetail } from "../../../../components";
import StateDot from "./StateDot";

import Api from "../../../../api";
import { MEMBER } from "../../../../constants";
import { formatDate } from "../../../../settings";
import { getWeekDates } from "../../../../utils";
import moment from "moment";

const { Text } = Typography;

const useCols = () => {
	const { t } = useMemberTranslation();
	const { week, expand } = useSelector((state) => state.member);
	const columns = useMemo(() => {
		return [
			{
				title: <span data-testid="fullname-sorter">{t("fullname")}</span>,
				dataIndex: "fullname",
				className: "capitalize",
				sorter: true,
				render: (fullname, { default_lunch, notes }) => {
					return (
						<>
							<div className="flex items-center">
								<StateDot booked={default_lunch ?? 1} ate={1} size="sm" />
								<LongText className="ml-1" max={MEMBER.MAX_DISPLAY_NAME}>
									{fullname}
								</LongText>
							</div>
							<LongText
								className="text-[0.5625rem]"
								max={MEMBER.MAX_DISPLAY_NOTE}>
								{notes}
							</LongText>
						</>
					);
				},
			},
			{
				title: t("office"),
				dataIndex: "office_code",
				className: "capitalize",
				render: (office_code, { office_title }) => (
					<Tooltip title={office_title} placement="bottom">
						{office_code}
					</Tooltip>
				),
			},
		];
	}, [t]);
	const dayCols = useMemo(() => {
		const DaysInWeek = getWeekDates(week);
		return DaysInWeek.map((day) => ({
			title: (
				<Text type={day === moment().format(formatDate) ? "danger" : ""}>
					{day}
				</Text>
			),
			dataIndex: "history",
			align: "center",
			width: "10rem",
			render: (value) => {
				let dayData = {};
				if (Array.isArray(value)) {
					dayData = value.find(
						(value) =>
							moment(value?.date, MEMBER.API_FORMAT_DATE).format(formatDate) ===
							day
					);
				}
				return (
					<StateDot
						booked={dayData?.booked}
						ate={dayData?.ate}
						menu={dayData?.menu}
						expand={expand}
					/>
				);
			},
		}));
	}, [week, expand]);

	const newCols = columns.concat(dayCols);
	newCols.push({
		width: "10%",
		render: (_, { username }) => (
			<TableDetail
				id={username}
				pageName="member"
				handleDelete={async () => {
					return Api.members.deleteById(username);
				}}
			/>
		),
	});
	return newCols;
};

export default useCols;
