export const routePaths = {
  home: "/",
  login: "/login",
  store: "/store",
  office: "/office",
  member: "/member",
  dish: "/dish",
  todayBook: "/todayBook",
  statistic: "/statistic",
  menus: "/menus",
  release: "/release-note",
};

export const afterLoginRoute = routePaths.statistic;
