import React from "react";

function AntSpin() {
	return (
		<div className="ant-spin-nested-loading w-[100%] h-60 flex items-center justify-center bg-opacity-10 bg-slate-50">
			<div
				className="ant-spin ant-spin-spinning"
				aria-live="polite"
				aria-busy="true">
				<span className="ant-spin-dot ant-spin-dot-spin">
					<i className="ant-spin-dot-item"></i>
					<i className="ant-spin-dot-item"></i>
					<i className="ant-spin-dot-item"></i>
					<i className="ant-spin-dot-item"></i>
				</span>
			</div>
		</div>
	);
}

export default AntSpin;
