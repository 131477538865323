import { Table } from "antd";
import React from "react";
import useColumns from "./column";

function Booking({ history }) {
	const columns = useColumns();

	return (
		<>
			<Table
				rowKey="id"
				bordered
				dataSource={history}
				columns={columns}
				rowClassName="editable-row"
				pagination={false}
				scroll={{ y: 700 }}
			/>
		</>
	);
}

export default Booking;
