import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMemo } from "react";
import Api from "../../../../api";
import { LongText, TableDetail } from "../../../../components";
import { RULES } from "../../../../constants";
import { formatTimeX } from "../../../../settings";
import { useOfficeTranslation } from "../../../../utils/customHooks";
export default function useCreateColumns() {
  const { t } = useOfficeTranslation();
  const MaxText = (text) => (
    <LongText max={RULES.MAX_DISPLAY_LEN}>{text}</LongText>
  );
  return useMemo(() => {
    const columns = [
      {
        title: t("office"),
        dataIndex: "title",
        width: "25%",
        sorter: true,
        render: MaxText,
      },
      {
        title: t("code"),
        dataIndex: "code",
        width: "15%",
      },
      {
        title: t("defaultMenu"),
        dataIndex: "menu_title",
        width: "25%",
        render: MaxText,
        sorter: true,
      },
      {
        title: t("bookingTime"),
        dataIndex: "time",
        width: "20%",
        render: (time, record) => {
          return (
            <span className="justify-center">
              <span className="mr-1">
                {moment(record.starttime, formatTimeX).format(formatTimeX)}
              </span>{" "}
              <ArrowRightOutlined className="mr-1" />{" "}
              <span>
                {moment(record.endtime, formatTimeX).format(formatTimeX)}
              </span>
            </span>
          );
        },
      },
      {
        width: "15%",
        render: (_, { id }) => (
          <TableDetail
            id={id}
            pageName="office"
            handleDelete={async () => {
              return Api.offices.deleteById(id);
            }}
          />
        ),
      },
    ];
    return columns;
  }, [t]);
}
