import translations from "./translations";
import store from "./store";
import office from "./office";
import member from "./member";
import statistic from "./statistic";
import todayBook from "./todayBook";
import office_menus from "./office_menus";
import menus from "./menus";
const vi = {
  translations,
  store,
  office,
  member,
  statistic,
  todayBook,
  office_menus,
  menus,
};

export default vi;
