import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FilterForm, Filter, SearchInput } from "../../../components";
import Api from "../../../api";
import { useDispatch } from "react-redux";
import { setPage } from "../../../store/reducers/commonSlice";

function MemberFilterForm() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<FilterForm
			className="mt-2"
			handleFinish={(values) => {
				const office_id = values?.office_id?.join("-");
				dispatch(setPage({ office_id, keyword: values.keyword, page: 1 }));
			}}>
			<Filter
				label={t("office")}
				name="office_id"
				apiCall={Api.offices.getAll}
				mode="multiple"
				optionKeys={{
					title: "code",
				}}
			/>
			<SearchInput placeholder={`${t("name")} ${t("member")}`} />
		</FilterForm>
	);
}
export default memo(MemberFilterForm);
