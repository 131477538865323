import React from "react";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

function AddButton({ handleClick, children }) {
	return (
		<Button
			data-testid="add-button"
			onClick={handleClick}
			type="primary"
			icon={<PlusCircleFilled />}
			className="uppercase">
			{children}
		</Button>
	);
}
export default AddButton;
