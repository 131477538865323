import React from "react";
import { Form, Select } from "antd";
import { InputField } from "../../../components";
import { useMemberTranslation } from "../../../utils/customHooks";
import { RULES } from "../../../constants";

const { Option } = Select;
const { Item } = Form;

function DetailForm({ offices }) {
	const { t } = useMemberTranslation();
	return (
		<>
			<InputField label={t("fullname")} name="fullname" disabled />
			<InputField label={t("email")} name="email" disabled />
			<InputField
				label={t("note")}
				name="notes"
				rules={[
					{
						max: RULES.MAX_INPUT_LEN,
					},
				]}
				placeholder={t("noteExample")}
			/>
			<Item
				label={t("office")}
				name="office_id"
				rules={[
					{
						required: true,
					},
				]}>
				<Select>
					{offices.map((store) => (
						<Option
							data-testid={`select-office_id-option_${store.id}`}
							key={store.id}
							value={store.id}>
							{store.title}
						</Option>
					))}
				</Select>
			</Item>
			<Item
				label={t("default")}
				name="default_lunch"
				rules={[
					{
						required: true,
					},
				]}>
				<Select>
					<Option data-testid="select-default_lunch-option-1" value={1}>
						{t("yes.t")}
					</Option>
					<Option data-testid="select-default_lunch-option-0" value={0}>
						{t("no.t")}
					</Option>
				</Select>
			</Item>
		</>
	);
}

export default DetailForm;
