import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import login from "../../api/login";
import { setToken } from "../../api/Store/localStorage";
import { afterLoginRoute } from "../../constants/routes";

function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const onFinish = ({ remember, ...values }) => {
    setLoading(true);
    login(values)
      .then(({ data }) => {
        if (data?.data) {
          const { access_token } = data?.data;
          setToken(access_token);
          navigate(afterLoginRoute);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error?.data?.message || error?.response?.data?.message);
      });
  };

  return (
    <div className="bg-stone-600 flex justify-center items-center min-h-screen">
      <Form
        data-testid="login-form"
        className="bg-white p-6 rounded-lg min-w-[600px]"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <h1 className="text-3xl text-blue-400 mb-12">Login</h1>
        <Form.Item
          label="Username"
          name="username"
          labelCol={{ span: 4, offset: 0 }}
          wrapperCol={{ offset: 0, span: 20 }}
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input data-testid="login-form_username" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          labelCol={{ span: 4, offset: 0 }}
          wrapperCol={{ offset: 0, span: 20 }}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password data-testid="login-form_password" />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          className="mb-0"
          wrapperCol={{ offset: 4, span: 8 }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        {error && error !== "" && (
          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <div className="text-red-500 text-right">{error}</div>
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
          <Button
            data-testid="login-form_submit"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
