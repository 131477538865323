import { useEffect, useState } from "react";
import { showErrMsg } from "../../components";

export default function useFetchData({
	apiCall,
	data,
	handleThen,
	deps = [],
	initValue = [],
}) {
	const [resData, setResData] = useState(initValue);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (typeof apiCall === "function") {
			apiCall(data)
				.then((res) => {
					if (typeof handleThen === "function") handleThen(res);
					setResData(res.data.data);
				})
				.catch((error) => {
					showErrMsg(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line
	}, deps);

	return {
		loading,
		resData,
	};
}
