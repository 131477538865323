import React from "react";
import PageLayout from "../PageLayout";
import StoreForm from "./components/StoreForm";
import StoreTable from "./components/StoreTable";

function Store() {
	return (
		<PageLayout heading="store">
			<StoreTable className="mt-5" />
			<StoreForm />
		</PageLayout>
	);
}

export default Store;
