import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  page: {
    page: 1,
    total: 0,
    limit: 10,
    keyword: "",
    orderby: "",
  },
  otherParams: null,
  deleteId: null,
  drawer: {
    visible: false,
    id: null,
  },
  isUpdate: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      if (state.data !== payload) state.data = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    openModal: (state, { payload }) => {
      state.drawer.visible = true;
      if (payload && payload !== state.drawer.id) state.drawer.id = payload;
    },
    closeModal: (state) => {
      state.drawer.visible = false;
      state.drawer.id = null;
    },
    setTotal: (state, { payload }) => {
      state.page.total = payload;
    },
    search: (state, { payload }) => {
      state.page.keyword = payload;
    },
    setPage: (state, { payload }) => {
      const otherParams = { ...state.otherParams };
      for (const key in payload) {
        const element = payload[key];
        if (
          Object.hasOwnProperty.call(state.page, key) &&
          state.page[key] !== element
        ) {
          state.page[key] = element;
        } else if (otherParams?.[key] !== element) {
          otherParams[key] = element;
        }
      }
      state.otherParams = otherParams;
    },
    update: (state) => {
      state.isUpdate = !state.isUpdate;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const {
  setData,
  setLoading,
  setDeleteId,
  setShowModal,
  openModal,
  closeModal,
  setTotal,
  search,
  setPage,
  update,
  reset,
} = commonSlice.actions;

export default commonSlice.reducer;
