import React from "react";
import { useSelector } from "react-redux";

import { TableWithData } from "../../../../components";
import StoreFilter from "./StoreFilter";

import { useCreateColumns } from "./data";
import { useFetchToStore } from "../../../../utils/customHooks";
import Api from "../../../../api";
import { parseStores } from "../../../../utils/parseUtils";

export default function StoreTable({ className = "" }) {
	const { otherParams } = useSelector((state) => state.common);
	const columns = useCreateColumns();

	useFetchToStore({
		apiCall: Api.stores.get,
		handleData: parseStores,
		deps: otherParams?.office_code,
	});

	return (
		<>
			<StoreFilter className="mt-5" />
			<TableWithData className={className} columns={columns} />
		</>
	);
}
