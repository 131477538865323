import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Form, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function FilterForm({ className = "", children, handleFinish }) {
	const { loading } = useSelector((state) => state.common);
	return (
		<Form
			layout="vertical"
			className={className}
			onFinish={(values) => {
				if (typeof handleFinish === "function") handleFinish(values);
			}}>
			<Space size={25} align="end">
				{children}
				<Button
					type="primary"
					htmlType="submit"
					data-testid="filter-form_submit"
					icon={<SearchOutlined />}
					loading={loading}
				/>
			</Space>
		</Form>
	);
}

export default memo(FilterForm);
export { default as SearchInput } from "./SearchInput";
export { default as FilterWithLabel } from "./FilterWithLabel";
export { default as Filter } from "./Filter";
