import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
	week: moment().format(),
	expand: false,
};

const memberSlice = createSlice({
	name: "member",
	initialState,
	reducers: {
		setWeek: (state, { payload }) => {
			if (state.week !== payload) state.week = payload;
		},
		setExpand: (state, { payload }) => {
			state.expand = payload;
		},
	},
});

export const { setWeek, setExpand } = memberSlice.actions;
export default memberSlice.reducer;
