import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrMsg } from "../../components";
import {
	reset,
	setData,
	setLoading,
	setTotal,
} from "../../store/reducers/commonSlice";

export default function useFetchToStore({
	apiCall,
	handleData,
	deps,
	defaultParams = {},
}) {
	const dispatch = useDispatch();
	const { page, isUpdate, otherParams } = useSelector((state) => state.common);

	const newDeps = [
		page.page,
		page.limit,
		page.keyword,
		page.orderby,
		isUpdate,
	].concat(deps);

	useEffect(() => {
		dispatch(setLoading(true));
		const newParams = Object.assign({}, otherParams, page);
		Object.keys(newParams).forEach((key) => {
			if (!newParams[key]) {
				if (defaultParams?.[key]) {
					newParams[key] = defaultParams[key];
				} else {
					delete newParams[key];
				}
			}
		});

		apiCall(newParams)
			.then(({ data }) => {
				const newData =
					typeof handleData === "function" ? handleData(data.data) : data.data;
				dispatch(setData(newData));
				dispatch(setTotal(data.total));
			})
			.catch(showErrMsg)
			.finally(() => dispatch(setLoading(false)));

		return () => {
			dispatch(reset());
		};
		// eslint-disable-next-line
	}, newDeps);
}
