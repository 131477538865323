import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { openModal } from "../../store/reducers/commonSlice";
import PageLayout from "../PageLayout";
import InfoDish from "./InfoDish/InfoDish";

function Dish() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [title, setTitle] = useState(null);
	const [btn, setBtn] = useState(null);
	const openAddLunch = () => {
		dispatch(openModal());
		setBtn(t("function.confirm"));
		setTitle(t("function.add", { val: "dish" }));
	};
	return (
		<PageLayout heading="dish" handleAction={openAddLunch}>
			<InfoDish title={title} setTitle={setTitle} btn={btn} setBtn={setBtn} />
		</PageLayout>
	);
}

export default Dish;
