import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Api from "../../api";
import { FilterForm, SearchInput, TableWithData } from "../../components";
import { setPage } from "../../store/reducers/commonSlice";
import { useFetchToStore } from "../../utils/customHooks";
import PageLayout from "../PageLayout";
import useCreateColumns from "./component/Columns/columns";
import FormOffice from "./component/formOffice";
import "./index.css";

function Office() {
	const { t } = useTranslation();
	const columns = useCreateColumns();
	const dispatch = useDispatch();

	useFetchToStore({
		apiCall: Api.offices.get,
		handleData: (data) => {
			return data;
		},
		deps: [],
	});

	return (
		<PageLayout heading="office">
			<FormOffice />
			<div className="Office p-0">
				<FilterForm
					handleFinish={(values) => {
						dispatch(
							setPage({
								keyword: values.keyword,
								page: 1,
							})
						);
					}}>
					<SearchInput placeholder={`${t("name")} ${t("office")}`} />
				</FilterForm>
				<TableWithData className="mt-6" columns={columns} />
			</div>
		</PageLayout>
	);
}

export default Office;
