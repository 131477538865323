import { Tooltip } from "antd";
import React from "react";

const LongText = ({ children, max, className }) => {
	if (typeof children === "string" && children.length > max) {
		return (
			<Tooltip placement="bottomRight" title={children}>
				<p className={"m-0" + (className ? " " + className : "")}>
					{children.slice(0, max) + "..."}
				</p>
			</Tooltip>
		);
	}
	return (
		<p className={"m-0" + (className ? " " + className : "")}>{children}</p>
	);
};
export default LongText;
