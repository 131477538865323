import { Layout, Menu } from "antd";

import React from "react";
import { sidebarItems } from "../constants/sidebar";
import { afterLoginRoute, routePaths } from "../constants/routes";

const { Content, Sider } = Layout;

export const MainLayout = ({ children }) => {
  return (
    <Layout className="min-h-screen">
      <Sider width={200}>
        <Menu
          mode="inline"
          defaultSelectedKeys={afterLoginRoute}
          style={{
            height: "100%",
          }}
          items={sidebarItems}
        />
      </Sider>
      <Content
        style={{
          padding: "0 24px",
          minHeight: 280,
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};
