import { DatePicker, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListParams } from "../../../../store/reducers/reportSlice";
import { useStatisticTranslation } from "../../../../utils/customHooks";
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

function SelectDate() {
  const { t } = useStatisticTranslation();
  const [link, setLink] = useState("")
  const dispatch = useDispatch();
  const { listParams } = useSelector((state) => state.report);

  useEffect(() => {
    if (listParams.from && listParams.to) {
      const { from, to } = listParams;
      setLink(process.env.REACT_APP_URL_BOOKING + "v1/export?from=" + from + "&to=" + to)
    } else {
      setLink("")
    }
  }, [listParams])
  const handleDate = (value, dateString) => {
    return dispatch(
      setListParams({
        ...listParams,
        from: dateString[0],
        to: dateString[1],
      })
    );
  };
  return (

    <>
      <div className="mt-11">
        <Title level={5}>{t("time")}</Title>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Select
            value={listParams.type}
            onChange={(value) =>
              dispatch(
                setListParams({
                  ...listParams,
                  type: value,
                })
              )
            }
            bordered={false}
          >
            <Option value="day">{t("date")}</Option>
            <Option value="week">{t("week")}</Option>
            <Option value="month">{t("month")}</Option>
          </Select>
          <RangePicker
            onChange={handleDate}
            picker={listParams.type}
            format="YYYY-MM-DD"
          />
        </div>
        {link && <a href={link} target="_blank">Download Excel</a>}
      </div>
    </>
  );
}
export default SelectDate;
