import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import AddButton from "./AddButton";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/reducers/commonSlice";

const { Title } = Typography;

export default function PageLayout({
	heading = "",
	handleAction,
	children,
	ActionBtn = null,
	addBtn = true,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<div>
			<div className="py-5 pl-6 pr-5 shadow-sm flex align-middle">
				<Title
					level={4}
					className="capitalize !mb-0 !font-medium"
					data-testid={heading}>
					{t(heading)}
				</Title>
				{addBtn && (
					<div className="ml-auto flex gap-4">
						{ActionBtn || (
							<AddButton
								handleClick={() => {
									if (typeof handleAction === "function") {
										handleAction();
									} else {
										dispatch(openModal());
									}
								}}>
								{t("function.add", { val: heading })}
							</AddButton>
						)}
					</div>
				)}
			</div>
			<div className="pl-6 pt-2 pr-5">{children}</div>
		</div>
	);
}
