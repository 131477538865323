export const STORES = "/v1/stores";
export const OFFICES = "/v1/offices";
export const MEMBERS = "/v1/users";
export const HISTORY = "/v1/history";
export const LOGIN = "/v1/login";
export const MENUS = "/v1/menus";
export const REPORT = "/v1/report_today";
export const OFFICES_MENU = "/v1/offices_menus";
export const STORES_MENUS = "/v1/stores_menus";
export const TODAY_BOOK = "/v1/list_today";
export const DASHBOARD = "/v1/report_today";
export const RELEASE_NOTE = "/v1/release_note";
export const BOOK_ALL = "/v1/cron/1";
export const SCAN = "/scan";
