import { Button } from "antd";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

function LoadingButton({
	asyncHandleClick = async (e) => {},
	children,
	...props
}) {
	const { t } = useTranslation("translations", {
		keyPrefix: "state",
	});
	const [loading, setLoading] = useState(false);

	const handleClick = useCallback(async (e) => {
		setLoading(true);
		try {
			await asyncHandleClick(e);
		} catch (error) {}
		setLoading(false);
	}, []);

	return (
		<Button
			loading={loading}
			className="capitalize"
			onClick={handleClick}
			type="primary"
			{...props}>
			{loading ? `${t("loading")} ...` : children}
		</Button>
	);
}

export default LoadingButton;
