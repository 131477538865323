import { Tag } from "antd";
import React, { useMemo } from "react";
import { LongText, TableDetail } from "../../../../../components";
import { useStoreTranslation } from "../../../../../utils/customHooks";

import { RULES } from "../../../../../constants";

import Api from "../../../../../api";

const LongTag = ({ children }) => (
	<Tag>
		<LongText max={RULES.MAX_DISPLAY_LEN}>{children}</LongText>
	</Tag>
);

const MenuTags = (key) => (values) => {
	if (Array.isArray(values)) {
		if (values.length > 2)
			return (
				<>
					<LongTag>{values[0][key]}</LongTag>
					<span>{`+${values.length - 1}`}</span>
				</>
			);
		return values.map((value, i) => <LongTag key={i}>{value[key]}</LongTag>);
	}
	return values;
};

const MaxText = (text) => (
	<LongText max={RULES.MAX_DISPLAY_LEN}>{text}</LongText>
);

export default function useCreateColumns() {
	const { t, i18n } = useStoreTranslation();

	return useMemo(() => {
		const columns = [
			{
				title: <span data-testid="store-title_sort">{t("name")}</span>,
				dataIndex: "title",
				sorter: true,
				className: "first-letter:uppercase",
				render: MaxText,
			},
			{
				title: t("address"),
				dataIndex: "address",
				className: "first-letter:uppercase",
				render: MaxText,
			},
			{
				title: t("contact"),
				dataIndex: "mobile",
				className: "first-letter:uppercase",
			},
			{
				title: t("menu"),
				dataIndex: "menus",
				className: "first-letter:uppercase",
				render: MenuTags("title"),
			},

			{
				title: <span data-testid="store-state_sort">{t("state")}</span>,
				dataIndex: "status",
				className: "first-letter:uppercase",
				sorter: true,
				render: (status) => (
					<Tag color={status ? "success" : "error"}>
						{i18n.t("state." + (status ? "active" : "locked"))}
					</Tag>
				),
			},
			{
				render: (_, { id }) => (
					<TableDetail
						id={id}
						pageName="store"
						handleDelete={async () => {
							return Api.stores.deleteById(id);
						}}
					/>
				),
			},
		];

		return columns;
	}, [i18n, t]);
}
