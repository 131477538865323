import React, { useState, useEffect } from "react";
import { Form, Tabs } from "antd";
import { useMemberTranslation } from "../../../utils/customHooks";
import { FormDrawer, showErrMsg } from "../../../components";
import { useSelector } from "react-redux";
import { useCallback } from "react";

import { MEMBER } from "../../../constants";
import DetailForm from "./DetailForm";
import Booking from "./booking";
import Api from "../../../api";
import moment from "moment";

const { TabPane } = Tabs;
function MemberForm() {
	const { t } = useMemberTranslation();
	const { id: username, visible } = useSelector(
		(state) => state?.common?.drawer
	);
	const [form] = Form.useForm();
	const [offices, setOffices] = useState([]);
	const [history, setHistory] = useState([]);
	const [toggle, setToggle] = useState(1);

	useEffect(() => {
		const initValue = async () => {
			if (toggle === 1) {
				const { data: resOffices } = await Api.offices.getAll();
				setOffices(resOffices.data);
				const { data } = await Api.members.getById(username);
				if (data?.data) {
					const fields = [];
					for (const key of MEMBER.API_FORM_KEYS) {
						fields.push({
							name: key,
							value: data.data[key],
						});
					}
					form.setFields(fields);
				}
			} else {
				await Api.history.getById(username).then(({ data }) => {
					const historyList = data.data;
					if (Array.isArray(historyList)) {
						const today = moment().format(MEMBER.API_FORMAT_DATE);
						if (historyList[0]?.date === today) {
							form.setFields([
								{
									name: "menu_id",
									value: historyList[0]?.menu_id ? historyList[0].menu_id : "",
								},
								{
									name: "ate",
									value: historyList[0]?.ate,
								},
								{
									name: "notes",
									value: historyList[0]?.notes,
								},
							]);
						} else {
							form.resetFields();
						}
						setHistory(historyList);
					}
				});
			}
		};
		if (visible && typeof username === "string") {
			initValue().catch(showErrMsg);
		}

		// eslint-disable-next-line
	}, [visible, username, toggle]);

	const handleFinish = useCallback(
		async (values) => {
			if (toggle === 1) {
				const data = Object.assign({ status: 1 }, values);
				const { email, ...newData } = data;
				await Api.members.editById(username, newData);
			} else {
				const today = moment().format(MEMBER.API_FORMAT_DATE);
				const rowKey = history.find((his) => {
					return his.date === today;
				});
				const newData = { ...values, booked: values?.menu_id ? 1 : 0 };
				await Api.history.modify(rowKey.id, newData);
			}
		},

		[username, toggle, history]
	);

	return (
		<FormDrawer
			title="member"
			handleSubmit={handleFinish}
			handleClose={() => setToggle(1)}
			form={form}
			isAdd={false}
			contentWrapperStyle={{
				width: "850px",
			}}>
			<Tabs
				onChange={(key) => {
					setToggle(+key);
				}}>
				<TabPane
					data-testid={`member-form-drawer_tab-${1}`}
					tab={t("infoBasic")}
					key={1}>
					<DetailForm offices={offices} />
				</TabPane>
				<TabPane
					data-testid={`member-form-drawer_tab-${2}`}
					tab={t("infoBookrice")}
					key={2}>
					<Booking history={history} />
				</TabPane>
			</Tabs>
		</FormDrawer>
	);
}

export default MemberForm;
