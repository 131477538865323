import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../api";
import { closeModal } from "../../../store/reducers/commonSlice";
import "./showListDish.css";

const ShowListDish = ({ title, btn, dataSource, setPagination }) => {
	const [mess, setMess] = useState("");
	const [form] = Form.useForm();
	const { confirm } = Modal;
	const dispatch = useDispatch();
	const drawer = useSelector((state) => state.common.drawer);
	const { t } = useTranslation();
	const [isDisabledInfoDish, setIsDisabledInfoDish] = useState(true);
	const CloseListDish = () => {
		confirm({
			title: t("message.confirm"),
			icon: <ExclamationCircleOutlined />,
			okText: t("function.ok"),
			onOk() {
				dispatch(closeModal());
				form.resetFields("");
				setIsDisabledInfoDish(true);
			},
			cancelText: t("function.cancel"),
			onCancel() {},
		});
	};
	const onFinish = async (values) => {
		const addDish = {
			...values,
			status: 1,
		};
		const Record = drawer?.id?.id;
		if (Record) {
			await Api.menus
				.editById(drawer?.id?.id, addDish)
				.then((response) => {
					message.success(
						t("alertMessage.success", {
							action: t("function.edit"),
						})
					);
					setIsDisabledInfoDish(true);
					dispatch(closeModal());
				})
				.catch((error) => {
					message.error(error?.data?.message);
				});
		} else {
			await Api.menus
				.add(addDish)
				.then((response) => {
					message.success(
						t("function.add", {
							val: t("state.success"),
						})
					);
					setIsDisabledInfoDish(true);
					dispatch(closeModal());
					form.resetFields();
				})
				.catch((error) => {
					message.error(error?.data?.message);
				});
		}
	};
	const onFinishFailed = (errorInfo) => {};
	const listPrevDish = dataSource.map((ele) => {
		return ele.title.toLowerCase();
	});
	const changeTitle = (e) => {
		setIsDisabledInfoDish(false);
		if (e.target.value.trim() >= "   ") {
			setIsDisabledInfoDish(false);
		} else {
			setIsDisabledInfoDish(true);
		}
		// const valueName = e.target.value.toLowerCase();
		// if (listPrevDish.indexOf(valueName) !== -1) {
		// setMess(t("NameDishExist"));
		// setIsDisabledInfoDish(true);
		// message.error('jkk')
		// } else {
		// setMess("");
		// setIsDisabledInfoDish(false);
		// }
	};
	const changeNote = () => {
		setIsDisabledInfoDish(false);
	};
	useEffect(() => {
		if (drawer?.id) {
			form.setFieldsValue({
				...drawer?.id,
			});
		} else {
			form.resetFields();
		}
	}, [drawer?.id, form]);
	return (
		<div>
			<Drawer
				getContainer={false}
				title={title}
				placement="right"
				visible={drawer.visible}
				onClose={CloseListDish}
				size={"large"}>
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					validateTrigger={["onBlur", "onChange"]}>
					<Form.Item
						label={t("dish")}
						name="title"
						rules={[
							{
								required: true,
								message: t("validateMsg.required"),
							},
							{
								max: 30,
								message: t("validateMsg.inRange", { min: 3, max: 30 }),
							},
							{
								whitespace: true,
								message: t("validateMsg.required"),
							},
							{
								min: 3,
								message: t("validateMsg.inRange", { min: 3, max: 30 }),
							},
						]}>
						<Input data-testid="inputDish" onChange={changeTitle} />
					</Form.Item>
					<Form.Item
						label={t("note")}
						name="description"
						rules={[
							{
								max: 200,
								message: t("validateMsg.maxLength", { val: 200 }),
							},
						]}>
						<Input data-testid="inputNote" onChange={changeNote} />
					</Form.Item>
					<Form.Item className="absolute bottom-0 right-7">
						<Button
							data-testid="btnAddDish"
							type="primary"
							htmlType="submit"
							disabled={isDisabledInfoDish}>
							{btn}
						</Button>
					</Form.Item>
				</Form>
				<span className="absolute top-[114px] left-[97px] text-[12px] text-[red]">
					{mess}
				</span>
			</Drawer>
		</div>
	);
};

export default ShowListDish;
