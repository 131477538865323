export const MAX_DISPLAY_NAME = 30;
export const MAX_DISPLAY_NOTE = 40;
export const API_FORMAT_DATE = "YYYY-MM-DD";
export const API_FORM_KEYS = [
	"fullname",
	"email",
	"notes",
	"default_lunch",
	"office_id",
];
