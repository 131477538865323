import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "antd";
import { showErrMsg } from "../../../../../../components";
import { CopyOutlined } from "@ant-design/icons";

import { TODAY_BOOK } from "../../../../../../constants";
import moment from "moment";
import "./CombineDataCell.css";
const { Text } = Typography;

function CombineDataCell({ offices, id }) {
	const { t } = useTranslation();
	const [copied, setCopied] = useState(false);
	const todayScript = useRef();

	const dishCount = (val) => t("todayBook:dish", { val });
	return (
		<div className="combine-data-cell table-cell combine-col">
			<div className="flex-1" ref={todayScript}>
				<h4 className="combine-data__today">{`${t("today")}: ${moment().format(
					TODAY_BOOK.FORMAT_DATE
				)}`}</h4>
				<ul>
					{Array.isArray(offices) &&
						offices.map((office) => (
							<li key={office.id}>
								<br />
								<Text className="block">{`${t("office")}: ${
									office?.title
								}`}</Text>
								<ul>
									{Array.isArray(office?.menu) &&
										office.menu.map((dish) => (
											<li key={dish.id}>
												<Text className="block">{`${dish.title}: ${dishCount(
													dish.count
												)}`}</Text>
												{/* {Array.isArray(dish?.notes) && dish.notes.length > 0 && (
													<ul>
														<Text className="block">{`${t("note")}:`}</Text>
														{dish.notes.map((note) => (
															<li className="pl-4" key={note?.username}>
																{note?.notes}
															</li>
														))}
													</ul>
												)} */}
											</li>
										))}
								</ul>
							</li>
						))}
				</ul>
			</div>
			<Button
				data-testid={`combine-cell_copy-btn-${id}`}
				icon={<CopyOutlined style={{ fontSize: "1.5rem" }} />}
				type="text"
				danger={copied}
				onClick={() => {
					navigator.clipboard
						.writeText(todayScript.current.innerText)
						.then(() => {
							if (!copied) setCopied(true);
						})
						.catch(showErrMsg);
				}}
			/>
		</div>
	);
}

export default CombineDataCell;
