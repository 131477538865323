import axiosClient from "./axiosClient";
import { URL } from "../constants";
import { default as history } from "./historyAPI";

const createApi = (key) => ({
	getAll() {
		return axiosClient.get(URL[key], {
			params: {
				limit: 0,
			},
		});
	},
	get(params) {
		return axiosClient.get(URL[key], {
			params,
		});
	},
	getById(id) {
		return axiosClient.get(URL[key] + `/${id}`);
	},
	add(data) {
		return axiosClient.post(URL[key], data);
	},
	deleteById(id) {
		return axiosClient.delete(URL[key] + `/${id}`);
	},
	deleteMany(idArr) {
		return axiosClient.delete(URL[key], { data: idArr });
	},
	editById(id, data) {
		return axiosClient.put(URL[key] + `/${id}`, data);
	},
});

const Api = {
	stores: {
		...createApi("STORES"),
		filterByMenu: (menu_id) => {
			return axiosClient.get(URL.STORES_MENUS, {
				params: {
					menu_id,
					limit: 0,
				},
			});
		},
	},
	menus: createApi("MENUS"),
	offices: createApi("OFFICES"),
	members: createApi("MEMBERS"),
	offices_menu: createApi("OFFICES_MENU"),
	history,
	report: createApi("REPORT"),
	today_book: {
		get: (params) => {
			return axiosClient.get(URL.TODAY_BOOK, { params });
		},
	},
	release_note: {
		get: (params) => {
			return axiosClient.get(URL.RELEASE_NOTE, { params });
		},
	},
	syncMember: async () => {
		return axiosClient.get(URL.SCAN);
	},
	bookAll: async () => axiosClient.get(URL.BOOK_ALL),
};

export default Api;
