import React, { memo } from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import "../FormDrawer.css"

function InputField({
	hidden,
	noStyle,
	label,
	name,
	placeholder,
	rules = [],
	shouldUpdate = (prev, curr) => prev[name] !== curr[name],
	disabled = false,
	field = {},
	suffix,
	...props
}) {
	const { t } = useTranslation();
	return disabled ? (
		<Form.Item
			noStyle={noStyle}
			label={label}
			rules={rules}
			shouldUpdate={shouldUpdate}
			{...field}>
			{({ getFieldValue }) => (
				<div className="flex justify-between items-center">
					{getFieldValue(name)}
					{suffix}
				</div>
			)}
		</Form.Item>
	) : (
		<Form.Item noStyle hidden={hidden} shouldUpdate={shouldUpdate}>
			{({ setFieldsValue }) => (
				<Form.Item
					noStyle={noStyle}
					label={label}
					rules={rules}
					name={name}
					shouldUpdate={false}
					{...field}>
					<Input
						onBlurCapture={(e) => {
							setFieldsValue({
								[name]: e.target.value.replace(/\s{2,}/g, " ").trim(),
							});
						}}
						placeholder={
							placeholder ? t("function.ex", { val: placeholder }) : ""
						}
						data-testid={`${name}_input`}
						{...props}
					/>
				</Form.Item>
			)}
		</Form.Item>
	);
}

export default memo(InputField);
