import React from "react";
import PageLayout from "../PageLayout";
import OfficeMenusTable from "./components/OfficeMenusTable";
import OfficeMenusForm from "./components/OfficeMenusForm";
import { Filter, FilterForm } from "../../components";

import Api from "../../api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/reducers/commonSlice";

function OfficeMenus() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<PageLayout heading="menu">
			<FilterForm
				handleFinish={(value) => {
					const office_id = value?.office_id.join("-");
					dispatch(
						setPage({
							office_id,
						})
					);
				}}>
				<Filter
					apiCall={Api.offices.getAll}
					label={t("office")}
					name="office_id"
					mode="multiple"
					optionKeys={{
						id: "id",
						value: "id",
						title: "code",
					}}
				/>
			</FilterForm>
			<OfficeMenusTable />
			<OfficeMenusForm />
		</PageLayout>
	);
}

export default OfficeMenus;
