import React, { useEffect, useState } from "react";
import { useStatisticTranslation } from "../../../../utils/customHooks";
import { Select, Typography } from "antd";
import TotalEat from "./TotalEat";
import Api from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { setListParams } from "../../../../store/reducers/reportSlice";
import { FilterWithLabel } from "../../../../components";
const { Option } = Select;

const { Title } = Typography;

const ShowTotalOffice = ({ dataWeek, dataDay }) => {
	const { t } = useStatisticTranslation();
	const [storeName, setStoreName] = useState([]);
	const dispatch = useDispatch();
	const { listParams } = useSelector((state) => state.report);

	useEffect(() => {
		Api.stores
			.getAll()
			.then((response) => {
				setStoreName(response?.data?.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return (
		<>
			<FilterWithLabel label={<Title level={5}>{t("store")}</Title>}>
				<Select
					defaultValue={t("AllStore")}
					className="w-[100px]"
					allowClear
					onChange={(value) => {
						dispatch(
							setListParams({
								...listParams,
								store_id: value,
							})
						);
					}}>
					<Option value="0">{t("AllStore")}</Option>
					{storeName.map((ele) => {
						return (
							<Option key={ele.id} value={ele.id}>
								{ele.title}
							</Option>
						);
					})}
				</Select>
			</FilterWithLabel>
			<div className="flex">
				<TotalEat
					total={dataDay?.[0]?.total}
					price={dataDay?.[0]?.price}
					title={t("day")}
					totalEat={t("totalEat")}
					totalPrice={t("totalPrice")}
				/>
				<div className="ml-[10%]">
					<TotalEat
						total={dataWeek?.[0]?.total}
						price={dataWeek?.[0]?.price}
						title={t("weekAll")}
						totalEat={t("totalEat")}
						totalPrice={t("totalPrice")}
					/>
				</div>
			</div>
		</>
	);
};

export default ShowTotalOffice;
