import React from "react";
import { Tooltip } from "antd";
// import statisticApi from '../../../api/statistic';

const TotalEat = (props) => {
  const textLong = (text, spec) => {
    if (text && text.length > spec) {
      return (
        <Tooltip title={text + "đ"}>{text.slice(0, spec) + "...đ"}</Tooltip>
      );
    } else {
      return text + "đ";
    }
  };
  const textLongTotal = (text, spec) => {
    if (text && text.length > spec) {
      return <Tooltip title={text}>{text.slice(0, spec) + "..."}</Tooltip>;
    } else {
      return text;
    }
  };
  const pri = Number(props.price)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const vnd = props.price > 0 ? textLong(pri, 11) : "0đ";

  return (
    <div>
      <div className="min-w-[400px] min-h-[164px] px-[20px] py-[10px] rounded-2xl shadow-md mt-[31px]">
        <p className="text-2xl font-normal text-[#FA8C16] mb-3">
          {props.title}
        </p>
        <div className="flex items-center h-[40px] ">
          <span className="text-xl pr-9 font-normal text-[#000000]">
            {props.totalEat}
          </span>
          <span
            data-testid={`totalEat${props.total}`}
            className="font-bold text-[35px]"
          >
            {textLongTotal(props.total?.toString(), 6)}
          </span>
        </div>
        <div className="flex items-center h-[40px]">
          <span className="text-xl pr-16 font-normal text-[#000000]">
            {props.totalPrice}
          </span>
          <span
            data-testid={`totalEat${props.price}`}
            className="font-bold text-[35px] pl-1"
          >
            {vnd}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalEat;
