import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { SORT_DIRECTIONS, PAGE_SIZES } from "../../constants";
import { setPage } from "../../store/reducers/commonSlice";

export default function TableWithData({
	className = "",
	columns = [],
	rowKey = "id",
	...props
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { data, loading, page } = useSelector((state) => state.common);

	const pageSizeOptions = useMemo(
		() => PAGE_SIZES.filter((size) => page.total >= size),
		[page.total]
	);

	return (
		<>
			<Table
				scroll={{
					x: "fit-content",
					scrollToFirstRowOnChange: true,
				}}
				onChange={(pagination, _, sorter) => {
					const orderby = sorter.order
						? `${sorter.field}-${
								sorter.order === "ascend"
									? SORT_DIRECTIONS.ASC
									: SORT_DIRECTIONS.DESC
						  }`
						: "";
					dispatch(
						setPage({
							orderby,
							limit: pagination.pageSize,
							page: pagination.current,
						})
					);
				}}
				pagination={{
					showSizeChanger: pageSizeOptions.length > 1,
					current: page.page,
					total: page.total,
					pageSize: page.limit,
					pageSizeOptions,
					locale: {
						items_per_page: "/ " + t("page"),
					},
					"data-testid": "table-pagination",
				}}
				sticky
				rowKey={rowKey}
				className={className}
				dataSource={data}
				columns={columns}
				loading={loading}
				{...props}
			/>
		</>
	);
}
