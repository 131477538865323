import { createSlice } from "@reduxjs/toolkit";
import { parseUtils } from "../../utils";

const initialState = {
	stores: [],
};

export const storeListSlice = createSlice({
	name: "stores",
	initialState,
	reducers: {
		setStores: (state, { payload }) => {
			state.stores = parseUtils.parseStores(payload);
		},
	},
});

export const { setStores } = storeListSlice.actions;

export default storeListSlice.reducer;
