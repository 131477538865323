import {
  RestOutlined,
  EnvironmentOutlined,
  UserOutlined,
  ShopOutlined,
  TeamOutlined,
  ContainerOutlined,
  BellOutlined,
  BarChartOutlined
} from "@ant-design/icons";
import { routePaths } from "./routes";
import { Link } from "react-router-dom";

const sidebarItemsConfig = [
  {
    icon: <RestOutlined />,
    path: routePaths.dish,
    label: "Món ăn",
  },
  {
    icon: <ShopOutlined />,
    path: routePaths.store,
    label: "Quán ăn",
  },
  {
    icon: <TeamOutlined />,
    path: routePaths.member,
    label: "Thành viên",
  },
  {
    icon: <EnvironmentOutlined />,
    path: routePaths.office,
    label: "Văn phòng",
  },
  {
    icon: <BellOutlined />,
    path: routePaths.todayBook,
    label: "Hôm nay",
  },
  {
    icon: <BarChartOutlined />,
    path: routePaths.statistic,
    label: "Thống kê",
  },
  {
    icon: <ContainerOutlined />,
    path: routePaths.menus,
    label: "Thực đơn",
  },
  {
    icon: <UserOutlined />,
    path: routePaths.login,
    label: "Đăng nhập",
  },
];

export const sidebarItems = sidebarItemsConfig.map((item) => {
  const { path, icon, label } = item;
  return {
    icon,
    key: path,
    label: <Link to={path}>{label}</Link>,
  };
});
