import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Store from "./pages/Store";
import Office from "./pages/Office";
import Member from "./pages/Member";
import TodayBook from "./pages/TodayBook";
import OfficeMenus from "./pages/OfficeMenus";
import Statistic from "./pages/Statistic";
import Dish from "./pages/Dish";
import Release from "./pages/Release";
import {
  addInitListener,
  addCustomMessageListener,
  sendCustomMessage,
} from "@luigi-project/client";
import { useEffect } from "react";
import { setToken } from "./api/Store/localStorage";
import { routePaths } from "./constants/routes";
import { MainLayout } from "./layouts/MainLayout";
let clientListener = null;
let authListener = null;

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <p className="App-logo">BOOKING</p>
      </header>
    </div>
  );
};

function App() {
  useEffect(() => {
    if (!clientListener) {
      clientListener = addInitListener((context) => {
        sendCustomMessage({ id: "joinClient" }); //Gửi thông báo Main là đã kết nối
      });
      if (!authListener) {
        authListener = addCustomMessageListener("authToken", (data) => {
          console.log("data", data);
          setToken(data.token);
        });
      }
    }
  }, []);

  return (
    <Routes>
      <Route path={routePaths.home} element={<Login />} />
      <Route path={routePaths.login} element={<Login />} />
      <Route
        path={routePaths.store}
        element={
          <MainLayout>
            <Store />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.office}
        element={
          <MainLayout>
            <Office />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.member}
        element={
          <MainLayout>
            <Member />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.dish}
        element={
          <MainLayout>
            <Dish />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.todayBook}
        element={
          <MainLayout>
            <TodayBook />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.statistic}
        element={
          <MainLayout>
            <Statistic />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.menus}
        element={
          <MainLayout>
            <OfficeMenus />
          </MainLayout>
        }
      />
      <Route
        path={routePaths.release}
        element={
          <MainLayout>
            <Release />
          </MainLayout>
        }
      />
    </Routes>
  );
}

export default App;
