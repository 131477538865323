import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMenusTranslation } from "../../../utils/customHooks";

import { Form, Select } from "antd";
import { FormDrawer, showErrMsg } from "../../../components";
import TableMenu from "./TableMenu";
import Api from "../../../api";
import { useCallback } from "react";
const { useForm } = Form;
const { Option } = Select;

function OfficeMenusForm() {
	const { id: office_id, visible } = useSelector(
		(state) => state.common.drawer
	);
	const isAdd = typeof office_id !== "number";
	const [form] = useForm();
	const [chosenDishes, setChosenDishes] = useState([]);
	const [listOffice, setListOffice] = useState([]);
	const [originalMenu, setOriginalMenu] = useState([]);
	const { t } = useMenusTranslation();

	useEffect(() => {
		const initValue = async () => {
			try {
				if (typeof office_id !== "number") {
					//when form is add form
					//Api to get offices that doesn't have menu
					const { data } = await Api.offices.get({
						nomenu: 1,
					});
					setListOffice(data?.data);
				} else {
					//Api to get menu when provide office_id
					const { data } = await Api.offices_menu.get({ office_id });
					const listDishes = data?.data;
					const defaultDish = listDishes.find((dish) => dish.isdefault === 1);
					setOriginalMenu(listDishes);
					setChosenDishes(
						listDishes.map((dish) => ({
							id: dish.menu_id,
							title: dish.menu_title,
						}))
					);
					const menuValues = {};
					listDishes.forEach((dish) => {
						menuValues[dish.menu_id] = { ...dish, office_id };
					});
					form.setFields([
						{
							name: "menu",
							value: menuValues,
						},
						{
							name: "defaultDish",
							value: defaultDish?.menu_id,
						},
					]);
				}
			} catch (error) {
				showErrMsg(error);
			}
		};
		if (visible) {
			initValue();
		} else {
			setChosenDishes([]);
		}
	}, [office_id, visible]);

	useEffect(() => {
		if (chosenDishes.length > 0) {
			const currentDefault = form.getFieldValue("defaultDish");
			if (chosenDishes.findIndex((dish) => dish.id === currentDefault) < 0) {
				form.setFieldsValue({
					defaultDish: chosenDishes[0].id,
				});
			}
		}
	}, [chosenDishes]);

	const onFinish = useCallback(
		async (values) => {
			const newOfficesMenu = chosenDishes.map((dish) => {
				const newMenu = Object.assign(
					{
						isdefault: dish?.id === values?.defaultDish ? 1 : 0,
					},
					values.menu?.[dish?.id]
				);
				if (values?.office_id) {
					newMenu.office_id = values.office_id;
				}
				return newMenu;
			});
			if (isAdd) {
				await Api.offices_menu.add(newOfficesMenu);
			} else {
				const listDeleteMenu = originalMenu.map((menu) => {
					return menu.id;
				});
				await Api.offices_menu.deleteMany(listDeleteMenu);
				await Api.offices_menu.add(newOfficesMenu);
			}
		},
		[originalMenu, chosenDishes]
	);
	return (
		<FormDrawer
			contentWrapperStyle={{
				width: "50%",
			}}
			form={form}
			handleSubmit={onFinish}
			title="menu"
			isAdd={isAdd}>
			{isAdd ? (
				<Form.Item
					className="form_drawer-tableMenus"
					label={t("office")}
					name="office_id"
					rules={[
						{
							required: true,
							message: t("AddOffice"),
						},
					]}>
					<Select placeholder={t("AddOffice")} allowClear>
						{listOffice.map((ele) => {
							return (
								<Option key={ele.id} value={ele.id}>
									{ele.title}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			) : (
				<div className="text-2xl font-bold">
					{originalMenu[0]?.office_title}
				</div>
			)}
			<TableMenu
				chosenDishes={chosenDishes}
				setDefaultDishSelection={setChosenDishes}
				office_id={office_id}
			/>
			<Form.Item label={t("defaultDish")} name="defaultDish">
				<Select placeholder={t("activeDish")} disabled={!chosenDishes.length}>
					{Array.isArray(chosenDishes) &&
						chosenDishes.map((dish) => {
							return (
								<Option key={dish.id} value={dish.id}>
									{dish.title}
								</Option>
							);
						})}
				</Select>
			</Form.Item>
		</FormDrawer>
	);
}

export default OfficeMenusForm;
