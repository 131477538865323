import React from "react";
import PageLayout from "../PageLayout";
import { Filter, FilterForm } from "../../components";
import Api from "../../api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setPage } from "../../store/reducers/commonSlice";
import HandmadeTable from "./components/HandmadeTable";
function TodayBook() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<PageLayout heading="today" addBtn={false}>
			<FilterForm
				handleFinish={(values) => {
					const { store_id: storeIdArray } = values;
					if (Array.isArray(storeIdArray)) {
						dispatch(setPage({ store_id: storeIdArray.join("-") }));
					}
				}}>
				<Filter
					mode="multiple"
					label={t("store")}
					name="store_id"
					apiCall={Api.stores.getAll}
				/>
			</FilterForm>
			<HandmadeTable />
		</PageLayout>
	);
}

export default TodayBook;
