import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import storesSlice from "./reducers/storesSlice";
import memberSlice from "./reducers/memberSlice";
import commonSlice from "./reducers/commonSlice";
import reportSlice from "./reducers/reportSlice";

export const store = configureStore({
	reducer: {
		user: userReducer,
		stores: storesSlice,
		member: memberSlice,
		common: commonSlice,
		report: reportSlice
	},
});
