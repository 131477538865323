import React, { useCallback, useMemo } from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import InputNumberCell from "./InputNumberCell";

import { MAX_PRICE } from "../../../../constants";
import { formatNumber, customHooks } from "../../../../utils";
import { currency } from "../../../../settings";
const { Option } = Select;

// create columns for table menu
function useCreateCols({ office_id, selectedRowKeys = [], allStores = [] }) {
	const { t, i18n } = customHooks.useMenusTranslation();
	const { getFieldValue, setFieldsValue } = Form.useFormInstance();

	const handleChangeStore = useCallback((value, menu_id) => {
		const menu = getFieldValue("menu");
		Object.assign(menu[menu_id], {
			store_id: value,
			menu_id,
			office_id,
		});
		setFieldsValue({
			menu,
		});
	}, []);

	return useMemo(() => {
		return [
			{
				title: t("dish"),
				dataIndex: "title",
				render: (dish, record) => {
					return (
						<div className="flex items-center justify-between">
							<Form.Item
								noStyle
								hidden
								name={["menu", record?.id, "office_id"]}>
								<Input />
							</Form.Item>
							<Form.Item noStyle hidden name={["menu", record?.id, "menu_id"]}>
								<Input />
							</Form.Item>
							<span>{dish}</span>
							{record?.description && (
								<Tooltip title={record?.description}>
									<InfoCircleOutlined />
								</Tooltip>
							)}
						</div>
					);
				},
			},
			{
				title: t("store"),
				dataIndex: "store",
				render: (_, record) => {
					const enable = selectedRowKeys?.includes(record?.id);
					return (
						<Form.Item
							className="selectStoreTableMenus"
							name={["menu", record?.id, "store_id"]}
							rules={[
								{
									validator(_, value) {
										if (!value && enable) {
											return Promise.reject("");
										}
										return Promise.resolve();
									},
								},
							]}>
							<Select
								allowClear={true}
								disabled={!enable}
								onChange={(e) => {
									handleChangeStore(e, record?.id);
								}}
								placeholder={i18n.t("function.select", {
									val: i18n.t("store"),
								})}>
								{allStores.map((store) => {
									if (store?.menu_id === record?.id && store?.store_title) {
										return (
											<Option key={store.store_id} value={store.store_id}>
												{store.store_title}
											</Option>
										);
									}
								})}
							</Select>
						</Form.Item>
					);
				},
			},
			{
				title: t("price"),
				dataIndex: "price",
				render: (_, record) => {
					const namePath = ["menu", record?.id, "price"];
					const enable = selectedRowKeys.includes(record?.id);
					return (
						<InputNumberCell
							enable={enable}
							namePath={namePath}
							rule={{
								validator: (_, value) => {
									if (enable) {
										if (!value) {
											return Promise.reject(t("enterPrice"));
										} else if (+value > MAX_PRICE) {
											return Promise.reject(
												t("highPrice", {
													val: formatNumber(MAX_PRICE),
													currency,
												})
											);
										}
									}
									return Promise.resolve();
								},
							}}
						/>
					);
				},
			},
			{
				title: (
					<>
						<span>{`${t("promotionPrice")} (${currency})`}</span>
						<Tooltip title={t("promotionCondition")}>
							<InfoCircleOutlined className="ml-4" />
						</Tooltip>
					</>
				),
				dataIndex: "priceten",
				render: (_, record) => {
					const namePath = ["menu", record?.id, "priceten"];
					const enable = selectedRowKeys.includes(record?.id);
					return (
						<InputNumberCell
							enable={enable}
							namePath={namePath}
							rule={{
								validator: (_, value) => {
									if (enable) {
										if (+value > getFieldValue(["menu", record?.id, "price"])) {
											return Promise.reject(t("highPromotionPrice"));
										}
									}
									return Promise.resolve();
								},
							}}
						/>
					);
				},
			},
		];
	}, [selectedRowKeys, allStores]);
}

export default useCreateCols;
