import { useTranslation } from "react-i18next";
export { default as useFetchData } from "./useFetchData";
export { default as useFetchToStore } from "./useFetchToStore";

export const useStoreTranslation = (options, pages = "") =>
  useTranslation(pages ? ["store"].concat(pages) : "store", options);

export const useOfficeTranslation = (options, pages = "") =>
  useTranslation(pages ? ["office"].concat(pages) : "office", options);

export const useMemberTranslation = (options, pages = "") =>
  useTranslation(pages ? ["member"].concat(pages) : "member", options);
export const useStatisticTranslation = (options, pages = "") =>
  useTranslation(pages ? ["statistic"].concat(pages) : "statistic", options);
export const useMenusTranslation = (options, pages = "") =>
  useTranslation(pages ? ["menus"].concat(pages) : "menus", options);
