import React, { memo } from "react";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import showErrMsg from "../Notifications/showErrMsg";
import showConfirmModal from "../Notifications/showConfirmModal";
import { useDispatch } from "react-redux";
import {
	openModal,
	setLoading,
	update,
} from "../../store/reducers/commonSlice";

function TableDetails({ id, handleDelete, pageName = "" }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	return (
		<>
			<Button
				data-testid={`table_detail-btn-${id}`}
				type="link"
				onClick={() => {
					dispatch(openModal(id));
				}}>
				{t("function.detail")}
			</Button>
			<Button
				data-testid={`table_delete-btn-${id}`}
				type="link"
				danger
				onClick={() => {
					showConfirmModal({
						title: t("message.delete", { val: pageName }),
						onOk: async () => {
							if (typeof handleDelete === "function") {
								dispatch(setLoading(true));
								try {
									await handleDelete();
									message.success(
										t("alertMessage.success", {
											action: t("function.del"),
										})
									);
									dispatch(update());
								} catch (error) {
									showErrMsg(error);
								}
								dispatch(setLoading(false));
							}
						},
					});
				}}>
				{t("function.del")}
			</Button>
		</>
	);
}

export default memo(TableDetails);
