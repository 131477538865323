import React, { memo } from "react";
import { WarningFilled } from "@ant-design/icons";
import { Form, Tooltip } from "antd";
import { InputNumberField } from "../../../../components";

function InputNumberCell({ enable, namePath, rule }) {
	return (
		<Form.Item noStyle shouldUpdate>
			{({ getFieldError }) => {
				const errors = getFieldError(namePath);
				return (
					<InputNumberField
						noStyle
						className="w-full"
						rules={[rule]}
						name={namePath}
						step={1000}
						disabled={!enable}
						prefix={
							enable && errors.length ? (
								<Tooltip title={errors[0]} defaultVisible>
									<WarningFilled />
								</Tooltip>
							) : (
								<div />
							)
						}
						status={errors.length ? "error" : undefined}
					/>
				);
			}}
		</Form.Item>
	);
}

export default memo(InputNumberCell);
