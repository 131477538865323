import React from "react";
import { useSelector } from "react-redux";
import Api from "../../api";
import AntNoData from "../../components/AntNoData";
import { useFetchToStore } from "../../utils/customHooks";
import PageLayout from "../PageLayout";
import Version from "./Version";

function Release() {
	const { data } = useSelector((state) => state.common);
	useFetchToStore({
		apiCall: Api.release_note.get,
		deps: [],
	});

	return (
		<PageLayout heading="release" addBtn={false}>
			{data?.length ? (
				<ul className="list-none p-0 m-0 child:mt-8 child-first:mt-0">
					{data.map((patch) => (
						<Version key={patch.version} {...patch} />
					))}
				</ul>
			) : (
				<div className="w-full h-full flex items-center justify-center">
					<AntNoData />
				</div>
			)}
		</PageLayout>
	);
}

export default Release;
