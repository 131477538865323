import { Tooltip } from "antd";
import React from "react";
import { RULES } from "../../../constants";

function LongText({ children, className }) {
	let processedText = "";
	if (typeof children === "string") {
		const words = children.split(" ");
		for (const word of words) {
			if (word.length > RULES.MAX_DISPLAY_LEN) {
				processedText += word.slice(0, RULES.MAX_DISPLAY_LEN) + "...";
				break;
			}
			processedText += word + " ";
		}
	}
	return processedText ? (
		<Tooltip placement="bottom" title={children}>
			{processedText}
		</Tooltip>
	) : (
		children
	);
}

export default LongText;
