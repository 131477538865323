import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";

function EyeButton({ className, defaultShow, onClick = () => {}, ...props }) {
	const [show, setShow] = useState(defaultShow);
	return (
		<Button
			className={className}
			icon={
				show ? (
					<EyeOutlined className="text-xl" />
				) : (
					<EyeInvisibleOutlined className="text-xl" />
				)
			}
			type={show ? "link" : "text"}
			onClick={(e) => {
				setShow((prev) => !prev);
				if (typeof onClick === "function") {
					onClick(!show, e);
				}
			}}
			{...props}
		/>
	);
}

export default EyeButton;
