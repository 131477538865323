import axiosClient from "./axiosClient";
import { URL } from "../constants";

const historyAPI = {
  getAll() {
    return axiosClient.get(URL.HISTORY, {
      params: {
        limit: 0,
      },
    });
  },
  modify(id, data) {
    const url = `${URL.HISTORY}/${id}`;
    return axiosClient.put(url, data);
  },
  getById(name, date) {
    const url = `${URL.HISTORY}`;
    return axiosClient.get(url, { params: { username: name, orderby: "date-DESC" } });
  },
};

export default historyAPI;
