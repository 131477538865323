import { DualAxes } from "@ant-design/charts";
import { Card, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useStatisticTranslation } from "../../../../utils/customHooks";
import moment from "moment";
import { formatDate } from "../../../../settings";
import { formatNumber } from "../../../../utils";

const { Title, Text } = Typography;

function ChartStatistics({ dataChart }) {
  const { t } = useStatisticTranslation();
  const { listParams } = useSelector((state) => state.report);
  const datas = [...dataChart].reverse();
  const handleData =
    datas &&
    datas.map((data) => {
      if (listParams.type === "day") {
        return {
          ...data,
          price: parseInt(data.price),
          date: moment(data.date).format(formatDate),
        };
      } else {
        return {
          ...data,
          price: parseInt(data.price),
        };
      }
    });

  const maxPrice = () => {
    const max = datas && Math.max(...handleData.map((data) => data.price));
    if (listParams.type === "month") {
      return max + 15500000;
    } else if (listParams.type === "week") {
      return max + 8000000;
    } else {
      return max + 1000000;
    }
  };
  const maxTotal = () => {
    const max = datas && Math.max(...handleData.map((data) => data.total));
    if (listParams.type === "month") {
      return max + 200;
    } else if (listParams.type === "week") {
      return max + 100;
    } else {
      return max + 10;
    }
  };
  const config = {
    data: [handleData, handleData],
    xField: "date",
    yField: ["price", "total"],
    padding: [5, 40, 30, 40],
    geometryOptions: [
      {
        geometry: "column",
        color: "#165DFF",
        columnStyle: {
          cursor: "pointer",
          radius: [5, 5, 0, 0],
        },
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 3,
          cursor: "pointer",
        },
        color: "#F759AB",
      },
    ],
    yAxis: {
      total: {
        min: 0,
        max: maxTotal(),
        formatter: (value) => formatNumber(value),
      },
      price: {
        grid: {
          line: {
            style: {
              stroke: "#dddd",
              lineDash: [4, 2],
            },
          },
        },
        max: maxPrice(),
        formatter: (value) => formatNumber(value),
      },
    },
    meta: {
      price: {
        alias: t("total", { val: t("price") }),
        formatter: (value) => formatNumber(`${value}`),
      },
      total: {
        alias: t("total", { val: t("suat") }),
        formatter: (value) => formatNumber(`${value}`),
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom-left",
      custom: true,
      offsetY: 4,
      items: [
        {
          name: t("total", { val: t("price") }),
          value: "price",
          marker: { symbol: "circle", style: { fill: "#165DFF", r: 5 } },
        },
        {
          name: t("total", { val: t("suat") }),
          value: "total",
          marker: { symbol: "circle", style: { fill: "#F759AB", r: 5 } },
        },
      ],
    },
  };
  return (
    <Card className="rounded-xl shadow-md mt-4">
      <Title level={3} className="p-3">
        {t("titleChart")}
      </Title>
      <div className="flex justify-between text-sm ">
        <Text className="text-neutral-400">
          {t("total", { val: t("price") })}
        </Text>
        <Text className="text-neutral-400">
          {t("total", { val: t("suat") })}
        </Text>
      </div>
      <DualAxes {...config} />
    </Card>
  );
}

export default ChartStatistics;
