import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

function Version({ version, conent, daterelease }) {
	const { t } = useTranslation();
	const time = moment(daterelease).format("LL");
	return (
		<li>
			<Typography.Title level={3} className="!mb-0">{`${t(
				"release"
			)} - [${version}]`}</Typography.Title>
			<Typography.Paragraph type="secondary">{time}</Typography.Paragraph>
			<div className="ml-4" dangerouslySetInnerHTML={{ __html: conent }}></div>
		</li>
	);
}

export default Version;
